import { ClientAttribute } from "./clientAttribute.model";

export class BasicClient {
    paramGuid: string;
    guid: string;
    name: string;
    authBgUrl: string;
    logoUrl: string;
    type: string;
    childClientRedirectEnabled: boolean = true;
    attributes: ClientAttribute[] = [];
    parentGuid : string;
    baseColor: string;
    baseColorPaletteCSV: string;
    parentChildRedirectToParentOnSignOut : boolean = false;
    isSuperClient: boolean = false;
    isAuth0Supported(): boolean {
        var attr = this.getAttribute(ClientAttribute.AUTH0_ENABLED);
        return attr != null && attr.value === 'true';
    }

    getAuth0Domain = (): string => {
        var attr = this.getAttribute(ClientAttribute.AUTH0_DOMAIN);
        return attr != null ? attr.value : null;
    }

    getAuth0ClientId = (): string => {
        var attr = this.getAttribute(ClientAttribute.AUTH0_CLIENT_ID);
        return attr != null ? attr.value : null;
    }

    getAttribute = (attrName): ClientAttribute => {
        return this.attributes ? this.attributes.find(o => o.name === attrName) : null;
    }

    constructor() {
    }
}
