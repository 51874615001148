import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { NavigationService } from 'src/app/services/navigation.service';
@Injectable({
  providedIn: 'root'
})

export class AuthenticationGuardService implements CanActivate {

  constructor(private router: Router, private authService: AuthService, private navigationService: NavigationService) { }

  canActivate(): boolean {
    if (this.authService.isLoggedIn()) {
      return true;
    }
    this.navigationService.navigateByClient("/auth/signin-v2"); 
    return false;
  }
}
