import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';
import 'rxjs/add/operator/catch';
import { throwError, of } from 'rxjs';
import { Router } from '@angular/router';
import { IfStmt } from '@angular/compiler';
import { catchError } from 'rxjs/operators';
import { DataTablesResponse } from '../models/DataTablesResponse.model';
import { ClientUser } from '../models/clientuser.model';
import { AuthService } from './auth.service';


@Injectable({
    providedIn: 'root'
})

export class UserService {
    siteChangeEvent = new EventEmitter<string>(); 
    userSelectedSitesCSV: string;
    constructor(private httpClient: HttpClient, private router: Router) {

    }
    public authenticate(email) {
        let _this = this;
        return this.httpClient.get(environment.userApi + '/authenticate');
    }
    public signUp(name, role) {
        return this.httpClient.post<any>(environment.userApi + '/signUp',
        {
            "clientUser": {
                "fullName": name
            }
        }
        , { headers: new HttpHeaders().set('Content-Type', 'application/json') });
    }
    public saveProfile(user: User) {
        return this.httpClient.post<any>(environment.userApi + '/updateProfile',
            user, { headers: new HttpHeaders().set('Content-Type', 'application/json') });
    }
    public saveUser(user: User) {
        return this.httpClient.post<any>(environment.userApi + '/save',
            user, { headers: new HttpHeaders().set('Content-Type', 'application/json') });
    }
    public addUser(clientUser: ClientUser) {
        return this.httpClient.post<any>(environment.userApi + '/add',
        clientUser, { headers: new HttpHeaders().set('Content-Type', 'application/json') });
    }
    public profileImageUpload(file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('isAsset', 'false');   
        return this.httpClient.post<any>(environment.apibaseUrl + '/upload/profile',
            formData);
    }
    public getAll(dataTablesParameters: any) {
        return this.httpClient.post<DataTablesResponse>(environment.userApi + '/getAll', dataTablesParameters, { headers: new HttpHeaders().set('Content-Type', 'application/json') });
    }
    public getStatusCount() {
        return this.httpClient.get(environment.userApi + '/getStatusCount');
    }
    public get(guid) {
        return this.httpClient.get(environment.userApi + '/get?guid='+guid);
    }
    public saveUserSelectedSites(userSeletedSitesCSV: string) {
        this.userSelectedSitesCSV = userSeletedSitesCSV;
        this.siteChangeEvent.emit(this.userSelectedSitesCSV);
        if(userSeletedSitesCSV.length == 0)
        {
            userSeletedSitesCSV="''";
        }
        return this.httpClient.post<any>(environment.userApi + '/saveSelectedSites',
        userSeletedSitesCSV, { headers: new HttpHeaders().set('Content-Type', 'application/json') });
    }
    public saveUserSelectedLayout(userSeletedLayout: string) {
        return this.httpClient.post<any>(environment.userApi + '/saveSelectedLayout',
        userSeletedLayout, { headers: new HttpHeaders().set('Content-Type', 'application/json') });
    }
    public saveUserSelectedTheme(userSeletedTheme: string) {
        return this.httpClient.post<any>(environment.userApi + '/saveSelectedTheme',
        userSeletedTheme, { headers: new HttpHeaders().set('Content-Type', 'application/json') });
    }
    public getSelectedSiteGuidsForSqlSP()
    {
        var stringCSV = "''";
        var sites = this.getSitesCSV();
        if(sites)
        {
            var stringCSV = "'" + sites + "'";
        }
        return stringCSV;
    }
    public getSelectedSiteGuidsForSql()
    {
        var stringCSV = "''";
        var sites = this.getSitesCSV();
        if(sites)
        {
            var stringCSV = "'" + sites.replace(/,/g, "','") + "'";
        }
        return stringCSV;
    }
    getSitesCSV()
    {
        if(this.userSelectedSitesCSV != undefined && this.userSelectedSitesCSV != null)
        {
            return this.userSelectedSitesCSV;
        }
        else
        {
            var usr: User = this.getCurrentUser();
            return usr.clientUser.selectedSiteCsv;
        }
    }
    getCurrentUser()
    {
      let cUser: User;
      if(sessionStorage.getItem('user') != null || sessionStorage.getItem('user') != undefined)
      {
          cUser = JSON.parse(sessionStorage.getItem('user'));
          return cUser;
      }
      return null;
    }
}