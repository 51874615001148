import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { environment } from 'src/environments/environment'
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  clientName: string;
  loadingMain: boolean = true;
  constructor(    private router: Router,
    private activatedRoute: ActivatedRoute  ) { 
      this.clientName = environment.defaultClinetName;
    }

  ngOnInit(): void {
    var _this = this;

    setTimeout(function() { _this.goToSV(); }, 2000);
  }
  goToSV()
  {
    this.router.navigateByUrl("client/" + environment.defaultClientId);
  }
}
