export interface IAuthState {

    get: any;
    authError: any;
    loading: boolean;
    user: any;
}

export const initialAuthState: IAuthState = {

    get: undefined,
    authError: undefined,
    loading: false,
    user: undefined,
};

