import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { User } from '../models/user.model';
import { BasicClient } from '../models/basicclient.model';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../store';
import jwt_decode from "jwt-decode";
import { ErrorNotifierService } from './errorNotifier.service';
import {
  SetLoading,SetLoadingSuccess
} from '../store/actions/auth.action';
import { NavigationService } from 'src/app/services/navigation.service';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})

export class AuthService {

  private newUser: User;
  CATEGORY_SYSTEM: string = "System";
  CATEGORY_REPORT: string = "Report";
  CATEGORY_DASHBOARD: string = "Dashboard";
  constructor(
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    private router: Router,
    private userService: UserService,
    private toastr: ToastrService,
    private store: Store<IAppState>,
    private httpClient: HttpClient, 
    private errorNotifierService:ErrorNotifierService  , private navigationService: NavigationService
  ) { }

  GoogleAuth(isSignUp) {
    return this.AuthLogin(new firebase.auth.GoogleAuthProvider(),isSignUp);
  }

  MicrosoftAuth(isSignUp) {
    return this.AuthLogin(new firebase.auth.OAuthProvider('microsoft.com'),isSignUp);
  }

  AppleIDAuth(isSignUp) {
    return this.AuthLogin(new firebase.auth.OAuthProvider('apple.com'),isSignUp);
  }

  EmailAuth(email, password,name, isSignUp) {
    var _this = this;
    if (isSignUp) {
      firebase.auth().createUserWithEmailAndPassword(email, password)
        .then((userCredential) => {
          // Signed in 
          var user = userCredential.user;
          _this.GetAndStoreToken(isSignUp, name, email, true);
        })
        .catch((error) => {
          _this.errorNotifierService.notifyFirebaseError(error,'firebase.createUserWithEmailAndPassword');
          _this.store.dispatch(new SetLoadingSuccess(false));
        });
    }
    else {
      firebase.auth().signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          // Signed in
          var user = userCredential.user;
          _this.GetAndStoreToken(isSignUp,'',email, true);
        })
        .catch((error) => {
          _this.errorNotifierService.notifyFirebaseError(error,'firebase.signInWithEmailAndPassword');
          _this.store.dispatch(new SetLoadingSuccess(false));
        });
    }
  }

   // Auth logic to run auth providers
  AuthLogin(provider, isSignUp) {
    var _this = this;
    return this.afAuth.signInWithPopup(provider)
      .then((result) => {
        console.log('You have been successfully logged in!');
        console.log(result);
        this.GetAndStoreToken(isSignUp);
      }).catch((error) => {
        _this.errorNotifierService.notifyFirebaseError(error,'firebase.signInWithPopup');
        _this.store.dispatch(new SetLoadingSuccess(false));
      })
  }

  GetAndStoreToken(isSignUp, name?, email?, custom?) {
    var _this = this;

    if(name == undefined || name == null)
    {
      name = '';
    }
    if(email == undefined || email == null)
    {
      email = '';
    }
    firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      console.log(idToken);
      _this.setToken(idToken);
      _this.startAutoRefreshToken(idToken); 
      if(isSignUp)
      {
        _this.userService.signUp(name,environment.defaultRoleId).subscribe(res => {
          console.log(res);
          if (res && res['code'] === 200 && res['data']) {
            sessionStorage.setItem("RegThanks","SHOWTHANKS");
            _this.store.dispatch(new SetLoadingSuccess(false));
          }
        }
        ,error => {
            if (error.status === 418) {
                // redirect to signup
                _this.navigationService.navigateByClient("/auth/signin-v2/1"); 
            }
            else
            {
              _this.errorNotifierService.notifyHttpError(error,'api.signUp');
            }
            _this.store.dispatch(new SetLoadingSuccess(false));
          }
        );  
      }
      else
      {
        _this.userService.authenticate(email).subscribe(
          res => {
              console.log(res);
              if (res && res['code'] === 200 && res['data']) {
                var usr: User = res["data"];
                _this.setCurrentUser(usr);
                _this.navigationService.GoToHome();
              }
            }
          ,error => {
              console.log(error);
              if (error.status === 401) {
                // redirect to signup
                _this.navigationService.navigateByClient("/auth/signup-v2/1");
              }
              else if (error.status === 511) {
                // redirect to signup
                _this.navigationService.navigateByClient("/auth/signup-v2/1");
              }
              else if (error.status === 412) {
                  // redirect to signup
                  sessionStorage.setItem("VEmail",email); 
                  var id = '2';
                  if(window.location.href.endsWith('/auth/signin-v2/2'))
                  {
                    id='3';
                  }
                  _this.navigationService.navigateByClient("/auth/signin-v2/"+id); 
              }
              else if (error.status === 424 ) {
                // redirect to signup
                 let em = 'we are unable to identify for organization, please contact administrator'
                _this.errorNotifierService.notifyError(em,'api.signUp1'); 
              }
              else if (error.status === 417 ) {
                // redirect to signup
                 let em = 'you access to the portal is not enabled, please contact administrator.'
                _this.errorNotifierService.notifyError(em,'api.signUp1'); 
              }
              else if (error.status === 506 ) {
                // redirect to signup
                 let em = 'Your signup request has been successfully submitted. The administrative team will contact you soon.'
                 _this.toastr.success(em);
              }
              else{
                _this.errorNotifierService.notifyHttpError(error,'api.authenticate');
                
              }
              _this.store.dispatch(new SetLoadingSuccess(false));
            }
        )
        ;  
      }
    }).catch(function (error) {
      _this.errorNotifierService.notifyFirebaseError(error,'firebase.getIdToken');
      _this.store.dispatch(new SetLoadingSuccess(false));
    });
  }

  SignOut(){
    var _this = this;
    clearInterval(_this.interval);
    firebase.auth().signOut().then(() => {
      console.log('You have been successfully logged out!');
      // Remove token
      _this.removeSession();
      _this.navigationService.navigateByClient("/auth/signin-v2");
    }).catch((error) => {
      _this.errorNotifierService.notifyFirebaseError(error,'firebase.signOut');
      _this.removeSession();
      _this.navigationService.navigateByClient("/auth/signin-v2");
    });
  }

  isLoggedIn()
  {
    console.log('checking logged in user');
    console.log(this.afAuth.currentUser);
    if(this.afAuth.currentUser != null || this.afAuth.currentUser != undefined)
    {
      var cUser = this.getCurrentUser();
      var cToken = this.getToken();
      if(cToken)
      {
        if(cUser)
        {
           return true;
        }
      }
    }
    return false;
  }
  ForgetPassword(email) {
    var _this = this;
    firebase.auth().sendPasswordResetEmail(email).then(() => {
      console.log('Reset password email sent!');
      this.toastr.success('Reset password email sent!');
    }).catch((error) => {
      _this.errorNotifierService.notifyFirebaseError(error,'firebase.sendPasswordResetEmail');
    });
  }

  ResendEmailVerification() {
    var _this = this;
    if (firebase.auth().currentUser == null)
      console.log('Firebase user not logged in!');
    else {
      firebase.auth().currentUser.sendEmailVerification().then(() => {
        console.log('Verification email resent!');
        this.toastr.success('Verification email resent!');
      }).catch((error) => {
        _this.errorNotifierService.notifyFirebaseError(error,'firebase.sendEmailVerification');
      });
    }
  }
  interval:any;
  public startAutoRefreshToken(idToken)
  {
    clearInterval(this.interval);
    var decodedUserInfoObj = jwt_decode(idToken);
    console.log(decodedUserInfoObj);
    var expiresAt = decodedUserInfoObj["exp"];
    var expTimeInMs = new Date(expiresAt*1000).getTime();
    var nowTimeInMs  =  Date.now();
    var diffInMs = expTimeInMs - nowTimeInMs;
    var bufferInMS = 300000 ; // refresh token 5 mins before expiration
    var refreshTokenAfter = diffInMs - bufferInMS; 
    let _this = this;
    if(refreshTokenAfter < 400000)
    {
      if(idToken)
      {
        if(firebase.auth().currentUser)
        {
            firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
              console.log(idToken);
              console.log('Renew Successfully');
              _this.setToken(idToken);
            }).catch(function (error) {
              _this.errorNotifierService.notifyFirebaseError(error,'firebase.getIdToken');
              _this.SignOut();
            });
        }
        else
        {
          _this.SignOut();
        }
      }
      else{
        clearInterval(_this.interval);
        _this.SignOut();
      }
    }
    console.log('refreshTokenAfter: '+refreshTokenAfter);

    _this.interval = setInterval(function() {
      var token = _this.getToken();
      if(token)
      {
        if(firebase.auth().currentUser)
        {
            firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
              console.log(idToken);
              console.log('Renew Successfully');
              _this.setToken(idToken);
            }).catch(function (error) {
              _this.errorNotifierService.notifyFirebaseError(error,'firebase.getIdToken');
              _this.SignOut();
            });
        }
        else
        {
          _this.SignOut();
        }
      }
      else{
        clearInterval(_this.interval);
        _this.SignOut();
      }
    }, refreshTokenAfter);
  }

  ChangePassword(newPassword) {
    let _this = this;
    if (firebase.auth().currentUser == null){
      console.log('Firebase user not logged in!');
      _this.SignOut();
      _this.navigationService.navigateByClient("/auth/signin-v2"); 
    }
    else {
      firebase.auth().currentUser.updatePassword(newPassword).then(() => {
        console.log('New password set!');
        _this.toastr.success('new password set successfully.');
      }).catch((error) => {
        console.log(error);
        _this.errorNotifierService.notifyFirebaseError(error,'firebase.updatePassword');
      });
    }
  }


  /** Sessio Storage ** */
  getCurrentUser()
  {
    let cUser: User;
    if(sessionStorage.getItem('user') != null || sessionStorage.getItem('user') != undefined)
    {
        cUser = JSON.parse(sessionStorage.getItem('user'));
        return cUser;
    }
    return null;
  }
  getCurrentClient()
  {
    let bClient: BasicClient;
    if(sessionStorage.getItem('client') != null || sessionStorage.getItem('client') != undefined)
    {
        bClient = JSON.parse(sessionStorage.getItem('client'));
        return bClient;
    }
    return null;
  }
  getToken()
  {
    if(sessionStorage.getItem('idToken') != null || sessionStorage.getItem('idToken') != undefined)
    {
        return sessionStorage.getItem('idToken');
    }
    return null;
  }
  setToken(token: string)
  {
    sessionStorage["idToken"] = token;
  }
  setCurrentUser(cUser: User)
  {
    sessionStorage["user"] = JSON.stringify(cUser);
  }
  clientSub: Subject<any> = new Subject();
  setCurrentClient(cbc: BasicClient)
  {
    sessionStorage["client"] = JSON.stringify(cbc);
    this.clientSub.next(cbc);
  }
  removeSession()
  {
    sessionStorage.removeItem('idToken');
    sessionStorage.removeItem('user');
  }

  canUserModify(component: string)
  {
    //return true;
    var usr: User = this.getCurrentUser();
    //systemMenu
    if(usr.clientUser.role.components)
    {
      var thisComponent = usr.clientUser.role.components.filter(c=> c.name == component);
      if(thisComponent.length>0)
      {
        if(thisComponent[0].canModify)
        {
          return true;
        }
        return false;
      }
    }
    return true;
  }
   
}