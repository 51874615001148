import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { errors } from '../resources/errors';
import { errorsProd } from '../resources/errors.prod';


@Injectable({
    providedIn: 'root'
  })
export class ErrorNotifierService {

  constructor(
    private toastr: ToastrService 
    ) { }

    notifyHttpError(error: HttpErrorResponse, key: string)
    {
        console.error('An http error has occurred:');
        console.error(error);

        let errorMsg: string;
        if (error.error && error.error.message) {
            errorMsg = error.error.message;
        } else {
            errorMsg = error.message;
        }
        this.showErrorToastrMsg(errorMsg,key);
    }
    notifyFirebaseError(error: HttpErrorResponse, key: string)
    {
        console.error('An firebase error has occurred:');
        console.error(error);
        this.showErrorToastrMsg(error.message,key);
    }

    notifyError(error: string, key: string)
    {
        console.error('An error occurred:', error);
        this.showErrorToastrMsg(error,key);
        
    }
    private showErrorToastrMsg(error: string, key: string)
    {
        let customMsg= this.getCustomErrorMessage(key);
        if(customMsg != null)
        {
            this.toastr.error(customMsg);
        }
        else
        {
            this.toastr.error(error);
        }
    }
    getCustomErrorMessage(key)
    {
        if(environment.production)
        {
            let errs = errorsProd.list.filter(t=>t.key === key);
            if(errs.length >0)
            {
                return errs[0].value;
            }
        }
        else
        {
            let errs = errors.list.filter(t=>t.key === key);
            if(errs.length >0)
            {
                return errs[0].value;
            }
        }
        return null;
    }

}
