<div class="container" *ngIf="this.flatConfig.layout === 'horizontal' && this.flatConfig.subLayout === 'horizontal-2'; else mainHeader">
    <ng-container *ngTemplateOutlet="mainHeader"></ng-container>
</div>
<ng-template #mainHeader>
    <div class="m-header">
        <a href="javascript:" class="mobile-menu" id="mobile-collapse" (click)="navCollapse()"><span></span></a>
        <span *ngIf="!showLogo" class="b-brand" [title]="clientName" style="    font-size: 1.3rem;color: #fff;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width: 160px;margin: 0;display: inline-block;">{{clientName}}</span>
        <img *ngIf="showLogo" [src]="clientLogo" style="max-width: 175px; max-height: 32px;" />
        <a class="mob-toggler" [ngClass]="{'on' : this.menuClass}" href="javascript:" (click)="toggleMobOption()"><i class="feather icon-more-vertical"></i></a>
    </div>
    <div class="collapse navbar-collapse" [style.display]="this.collapseStyle">
        <app-nav-left class="mr-auto"></app-nav-left>
        <app-nav-right class="ml-auto"></app-nav-right>
    </div>
</ng-template>