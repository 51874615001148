export const errorsProd = {
  list: [
    {
      key: "",
      value: ""
    },
    {
      key: "",
      value: ""
    }
]
};
