import { AuthActions, EAUTHActions } from '../actions/auth.action';
import { IAuthState, initialAuthState } from '../states/auth.state';

export function authReducers(state = initialAuthState, action: AuthActions): IAuthState {

    switch (action.type) {
        case EAUTHActions.GET_SUCCESS: {
            return {
                ...state,
                get: action.payload,
                authError: action.error
            };
        }
        case EAUTHActions.SET_LOADING_SUCCESS: {
            return {
                ...state,
                loading: action.payload
            };
        }
        case EAUTHActions.SET_USER_SUCCESS: {
            return {
                ...state,
                user: action.payload
            };
        }

        default: {
            return state;
        }
    }
};
