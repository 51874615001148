import { Component, NgZone, OnInit } from '@angular/core';
import { NextConfig } from '../../../app-config';
import { Location } from '@angular/common';
import { NavigationStart, Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router'
import { ClientService } from '../../../services/client.service'
import { AuthService } from '../../../services/auth.service'
import { ErrorNotifierService } from 'src/app/services/errorNotifier.service';
import { BasicClient } from 'src/app/models/basicclient.model';
import { User } from 'src/app/models/user.model';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  public flatConfig: any;
  public navCollapsed: boolean;
  public navCollapsedMob: boolean;
  public windowWidth: number;
  loadingMain: boolean= true;
  showTopbar: boolean = true;
  showSidebar: boolean = true;
  showBreadcrums: boolean = true;
  constructor(private zone: NgZone, private location: Location,private router: Router, public route: ActivatedRoute
    ,private errorNotifierService: ErrorNotifierService,
    private authService:AuthService,private clientService:ClientService) {
    this.flatConfig = NextConfig.config;
    let currentURL = this.location.path();
    const baseHerf = this.location['_baseHref'];
    if (baseHerf) {
      currentURL = baseHerf + this.location.path();
    }

    this.windowWidth = window.innerWidth;

    if (currentURL === baseHerf + '/layout/collapse-menu'
      || currentURL === baseHerf + '/layout/box'
      || (this.windowWidth >= 992 && this.windowWidth <= 1024)) {
      this.flatConfig.collapseMenu = true;
    }

    this.navCollapsed = (this.windowWidth >= 992) ? this.flatConfig.collapseMenu : false;
    this.navCollapsedMob = false;
      this.route.params.subscribe(params => {
        var bClient:BasicClient = this.authService.getCurrentClient();
        if(bClient.paramGuid !== params['clientId']
          || bClient.guid == undefined || 
          bClient.guid == null )
        {
          bClient = new BasicClient();
          this.authService.setCurrentClient(bClient);
          if(bClient.paramGuid != undefined && bClient.paramGuid != null)
          {
            this.clientService.getClientDetails()
            .subscribe(resp => {
              this.loadingMain= false;
              bClient.guid =  (resp["data"])["guid"];
              bClient.authBgUrl =  (resp["data"])["authBgUrl"];
              bClient.logoUrl =  (resp["data"])["logoUrl"];
              bClient.name =  (resp["data"])["name"];
              this.authService.setCurrentClient(bClient);
            }, error => {
              this.loadingMain = false;
              this.errorNotifierService.notifyHttpError(error, 'api.basicClient');
            }

            );
            
          }
        }
        else{
          this.loadingMain= false;
        }
        bClient.paramGuid = params['clientId'];
        var cUser: User = this.authService.getCurrentUser();
        if(cUser.clientUser.clientGuid !== bClient.paramGuid)
        {
          this.authService.SignOut();
        }
        
      }); 
  }
  clientId: string= '';
  ngOnInit() {
    if(this.router.url.indexOf('/dashboard/category') > 0)
    {
      this.showBreadcrums = false;
      this.showSidebar = false;
      this.showTopbar = false;
    }
    else
    {
      this.showBreadcrums = true;
      this.showSidebar = true;
      this.showTopbar = true;
    }
    this.router.events.subscribe(event =>{
      if (event instanceof NavigationStart){
         console.log(event.url)
         if(event.url.indexOf('/dashboard/category') > 0)
          {
            this.showBreadcrums = false;
            this.showSidebar = false;
            this.showTopbar = false;
          }
          else
          {
            this.showBreadcrums = true;
            this.showSidebar = true;
            this.showTopbar = true;
          }

      }
   })
    if (this.windowWidth < 992) {
      this.flatConfig.layout = 'vertical';
      setTimeout(() => {
        document.querySelector('.pcoded-navbar').classList.add('menupos-static');
        (document.querySelector('#nav-ps-flat-able') as HTMLElement).style.maxHeight = '100%'; // 100% amit
      }, 500);
    }

    var token = this.authService.getToken();
    if(token){
      this.authService.startAutoRefreshToken(token); 
    }
  }

  navMobClick() {
    if (this.windowWidth < 992) {
      if (this.navCollapsedMob && !(document.querySelector('app-navigation.pcoded-navbar').classList.contains('mob-open'))) {
        this.navCollapsedMob = !this.navCollapsedMob;
        setTimeout(() => {
          this.navCollapsedMob = !this.navCollapsedMob;
        }, 100);
      } else {
        this.navCollapsedMob = !this.navCollapsedMob;
      }
    }
  }

}
