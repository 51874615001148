import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { User } from '../models/user.model';
import { ToastrService } from 'ngx-toastr';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../store';
import jwt_decode from "jwt-decode";
import { ErrorNotifierService } from './errorNotifier.service';
import { ActivatedRoute } from '@angular/router'
import { BasicClient } from '../models/basicclient.model';
import { UserService } from './user.service';
import { Location } from '@angular/common'
import {
  SetLoading,SetLoadingSuccess
} from '../store/actions/auth.action';
import { ElementSchemaRegistry } from '@angular/compiler';
import { loading } from '../store/selectors';
@Injectable({
  providedIn: 'root'
})

export class NavigationService {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private location: Location
  ) { }

    getDefaultLandingPath()
    {
      var usr: User = this.userService.getCurrentUser();
      var systemNonDashboard: string = null;
      if(usr != undefined && usr != null)
      {
        if(usr.clientUser != undefined && usr.clientUser != null)
        {
          if(usr.clientUser.role != undefined && usr.clientUser.role != null)
          {
            if(usr.clientUser.role.systemMenu != undefined && usr.clientUser.role.systemMenu != null && usr.clientUser.role.systemMenu.length >0)
            {
              if(usr.clientUser.role.systemMenu[0].children != undefined && usr.clientUser.role.systemMenu[0].children != null && usr.clientUser.role.systemMenu[0].children.length >0)
              {
                if(usr.clientUser.role.systemMenu[0].title == 'Dashboard')
                {
                  var navUrl =  usr.clientUser.role.systemMenu[0].children[0].url;
                  return navUrl;
                }
                else
                {
                  systemNonDashboard =  usr.clientUser.role.systemMenu[0].children[0].url;
                }
              }
            }            
            if(usr.clientUser.role.clientMenu != undefined && usr.clientUser.role.clientMenu != null && usr.clientUser.role.clientMenu.length >0)
            {
              if(usr.clientUser.role.clientMenu[0].children != undefined && usr.clientUser.role.clientMenu[0].children != null && usr.clientUser.role.clientMenu[0].children.length >0)
              {
                var navUrl =  usr.clientUser.role.clientMenu[0].children[0].url;
                return navUrl;
              }
            }
            if(systemNonDashboard != null)
            {
                return systemNonDashboard;
            } 
          }
        }

        
      }
      return '/app/profile';
    }
    public navigateToDefaultByClient()
    {
      var defaultNavUrl = this.getDefaultLandingPath();
      console.log('default Nav: '+ defaultNavUrl);
      this.navigateByClient(defaultNavUrl);
    }
    public navigateBack()
    {
      this.location.back();
    }
    public navigateByClient(url: string)
    {
      let bClient: BasicClient;
      if(sessionStorage.getItem('client') != null || sessionStorage.getItem('client') != undefined)
      {
          bClient = JSON.parse(sessionStorage.getItem('client'));
          let clientId = bClient.paramGuid;
          var navUrl = "/client/"+clientId+url;
          console.log('navUrl:'+navUrl);
          this.router.navigateByUrl(navUrl);
      }
      else{
        if(url == '/auth/signin-v2')
        {
          var location = window.location.href;
          var idxClient = location.indexOf('/client/');
          if(idxClient > 0)
          {
            var subStr = location.substring(idxClient+ 8, location.length);
            var clientId_L = subStr.substring(0, subStr.indexOf('/'));

            var navUrl_L = "/client/"+clientId_L+url;
            console.log('navUrl:'+navUrl_L);
            this.router.navigateByUrl(navUrl_L);
          }
        }
        this.router.navigateByUrl('/');
      }
      
    }
    public navigateByClientGuid(url: string, clientGuid: string)
    {
      var navUrl = "/client/"+clientGuid+url;
      console.log('navUrl:'+navUrl);
      this.router.navigateByUrl(navUrl);      
    }
    public GoToHome()
    {
      //this.navigateByClient("/dashboard/default");
      this.navigateByClient("/dashboard/category");
    }
}
