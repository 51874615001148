import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NextConfig} from '../../../../app-config';
import {AuthService} from '../../../../services/auth.service'
@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  public flatConfig: any;
  public menuClass: boolean;
  public collapseStyle: string;
  public windowWidth: number;

  @Output() onNavCollapse = new EventEmitter();
  @Output() onNavHeaderMobCollapse = new EventEmitter();
  clientName:string="";
  clientLogo:string="";
  showLogo: boolean= false;
  constructor(private authService: AuthService) {
    this.flatConfig = NextConfig.config;
    this.menuClass = false;
    this.collapseStyle = 'none';
    this.windowWidth = window.innerWidth;
  }

  ngOnInit() { 
    var c = this.authService.getCurrentClient();
    if(c)
    {
      this.clientName= c.name;
      this.clientLogo = null;
      this.showLogo = false;
      if(c.logoUrl != null && c.logoUrl != undefined)
      {
        if(c.logoUrl.trim().length>0)
        {
          this.clientLogo = c.logoUrl;
          this.showLogo = true;
        }
      }
      
    }
    this.authService.clientSub.subscribe(
      val => {
        if(val)
        {
          this.clientName= val.name;
        }
      }
    );
    
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    //this.authService.clientSub.unsubscribe();
  }
  toggleMobOption() {
    this.menuClass = !this.menuClass;
    this.collapseStyle = (this.menuClass) ? 'block' : 'none';
  }

  navCollapse() {
    if (this.windowWidth >= 992) {
      this.onNavCollapse.emit();
      this.changeCopyrightVisibility();
    } else {
      this.onNavHeaderMobCollapse.emit();
      this.copyrightSetVisible();
    }
  }
changeCopyrightVisibility()
{
  if(document.getElementById('copyright'))
      {
        if(document.getElementById('copyright').style.visibility == "hidden")
        {
          document.getElementById('copyright').style.visibility = "visible";
        }
        else
        {
          document.getElementById('copyright').style.visibility = "hidden";
        }
        
      }
}
copyrightSetVisible()
{
  if(document.getElementById('copyright'))
  {
      document.getElementById('copyright').style.visibility = "visible";
    
  }
}
}
