<div class="column has-text-centered">
  <ng-container *ngFor="let image of albums; let i=index">
    <div class="img-row">
      <div style="text-overflow: ellipsis;overflow : hidden;white-space: nowrap; height: 20px;    margin-left: 15px; font-weight: bold; width: 280px;">{{image.title}}</div>
      <img class="img-frame" style="width: 300px; height: 300px;margin-top: 0px;margin-bottom: 0px;" [src]="image.thumb" (click)="open(i)"/>
      <div style="width:100%; text-align: right;padding-right: 15px;">
        <a title="delete image" style="cursor: pointer;font-size: 20px; margin-left: 10px;" (click)="delete(image.guid)"><i class="feather icon-trash"></i></a>
        <a title="edit image" style="cursor: pointer;font-size: 20px; margin-left: 10px;"  (click)="edit(image.guid)"><i class="feather icon-edit"></i></a>
      </div>
    </div> 
  </ng-container>
</div>
