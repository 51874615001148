import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { catchError } from 'rxjs/operators';
//for throw:
import { throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { ErrorNotifierService } from './errorNotifier.service';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap,take,switchMap } from 'rxjs/operators';
import 'rxjs/add/operator/catch';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private auth: AngularFireAuth,private router: Router, private authService: AuthService
        , private errorNotifierService : ErrorNotifierService , private navigationService: NavigationService ) 
    {
    console.log('token interceptor constructor');
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        if(req.url != '/assets/i18n/en.json')
        {
            let token = this.authService.getToken();
            let client = this.authService.getCurrentClient().paramGuid;
            var _this = this;
            if (req.url.endsWith('client/startup'))
            {
                req = req.clone({
                    setHeaders: {
                        Client: client
                    }
                } );
            }
            else if (token) {
                req = req.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`,
                        Client: client
                    }
                } );
            }
            else 
            {
                req = req.clone({
                    setHeaders: {
                        Client: client
                    }
                } );
            }
            if (req.url.endsWith('authenticate') || req.url.endsWith('signUp'))
            {
            return next.handle(req);
            }
            else
            {
                
                return next.handle(req).pipe(
                    catchError((err: HttpErrorResponse) => {
                    console.log(err);
                    if (err.status === 411) {
                        this.errorNotifierService.notifyError('This operation is not allowed. Please contact administrator.','http.411');
                    }
                    else if(err.status === 511) {
                        this.errorNotifierService.notifyError('User not found. Please contact administrator.','http.404');
                        this.authService.SignOut();
                    }
                    else if(err.status === 403) {
                        this.errorNotifierService.notifyError('User not active. Please contact administrator.','http.403');
                        this.authService.SignOut();
                    }
                    else if(err.status === 417) {
                        this.errorNotifierService.notifyError('Role not assigned. Please contact administrator.','http.417');
                        this.authService.SignOut();
                    }
                    else if(err.status === 424) {
                        this.errorNotifierService.notifyError('Client not found. Please contact administrator.','http.424');
                        this.authService.SignOut();
                    }
                    else if(err.status === 420) {
                        this.errorNotifierService.notifyError('you are not allowed to update user. Please contact administrator.','http.420');
                    }
                    else if(err.status === 451) {
                        this.errorNotifierService.notifyError('client not found. Please contact administrator.','http.420');
                        this.router.navigateByUrl('/');
                    }
                    else if (err.status === 401) {
                        if(firebase.auth().currentUser)
                        {
                            _this = this;
                            firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
                                console.log(idToken);
                                _this.authService.setToken(idToken);
                                req = req.clone({
                                    setHeaders: {
                                        Authorization: `Bearer ${token}`
                                    }
                                } );
                                return next.handle(req).pipe(
                                    catchError((err: HttpErrorResponse) => {
                                        console.log(err);                    
                                        return throwError(err);
                                    }));
                            }).catch(function (error) {
                                console.log(error);                    
                                _this.navigationService.navigateByClient("/auth/signin-v2");
                            });
                        }
                        else
                        {
                            this.authService.SignOut();
                        }
                    }
                    else
                    {
                        return throwError(err);
                    }    
                }
                ));
            }
        }
    }
}
