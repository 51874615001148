import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router'
import { ClientService } from '../../../services/client.service'
import { AuthService } from '../../../services/auth.service'
import { ErrorNotifierService } from 'src/app/services/errorNotifier.service';
import { BasicClient } from 'src/app/models/basicclient.model';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  clientId: string= '';
  loadingMain = true;
  constructor(private errorNotifierService: ErrorNotifierService,
    private authService:AuthService,
    private router: Router, public route: ActivatedRoute, private clientService:ClientService)
     { 
      this.route.params.subscribe(params => {
        var bClient:BasicClient = new BasicClient;
        bClient.paramGuid = params['clientId'];
        this.authService.setCurrentClient(bClient);
        if(bClient.paramGuid != undefined && bClient.paramGuid != null)
        {
          this.clientService.getClientDetails()
          .subscribe(resp => {
            this.loadingMain= false;
            bClient.guid =  (resp["data"])["guid"];
            bClient.authBgUrl =  (resp["data"])["authBgUrl"];
            bClient.logoUrl =  (resp["data"])["logoUrl"];
            bClient.name =  (resp["data"])["name"];
            this.authService.setCurrentClient(bClient);
          }, error => {
            this.loadingMain = false;
            this.errorNotifierService.notifyHttpError(error, 'api.basicClient');
          }

          );
          
        }
      }); 
     }

  ngOnInit() {
    var token = this.authService.getToken();
    if(token){
      this.authService.startAutoRefreshToken(token); 
    }

    //  Added by designer
      document.querySelector('body').classList.add('theme-1');
      document.querySelector('.pcoded-navbar').classList.add('menu-light');
      document.querySelector('body').classList.remove('flat-able-dark');
    //  Added by designer
  }

}
