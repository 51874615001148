import {Injectable} from '@angular/core';
import {AuthService} from'../../../../services/auth.service';
export interface NavigationItem {
  id: string;
  title: string;
  showTitle: boolean;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}
var defaultItem =
{
  id: 'navigation',
  title: '',
  type: 'group',
  showTitle: false,
  icon: 'feather icon-monitor',
  children: []
};
var system =
{
  id: 'navigation',
  title: 'Manage',
  type: 'group',
  showTitle: true,
  icon: 'feather icon-monitor',
  children: []
};
var report =
{
  id: 'reports',
  title: 'Reports',
  type: 'group',
  showTitle: true,
  icon: 'feather icon-monitor',
  children:[]
};
var appNav =
{
  id: 'app',
  title: 'app',
  type: 'group',
  hidden: true,
  icon: 'feather icon-align-left',
  children: [
    {
      id: 'Profile',
      title: 'Profile',
      type: 'item',
      url: '/app/profile',
      classes: 'nav-item',
      icon: 'feather icon-sidebar'
    },
    {
      id: 'ChangePassword',
      title: 'Change Password',
      type: 'item',
      url: '/app/changepassword',
      classes: 'nav-item',
      icon: 'feather icon-sidebar'
    }
  ]
};
const NavigationItems = [
  {
    id: 'navigation',
    title: '',
    type: 'group',
    showTitle: false,
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'collapse',
        icon: 'feather icon-home',
        children: [
          {
            id: 'default',
            title: 'Default',
            type: 'item',
            url: '/dashboard/default'
          }
        ]
      },
      {
        id: 'setup',
        title: 'Setup',
        type: 'collapse',
        icon: 'feather icon-sliders',
        children: [
          {
            id: 'cients',
            title: 'Clients',
            type: 'item',
            url: '/app/clients',
          }
        ]
      },
      {
        id: 'managment',
        title: 'Managment',
        type: 'collapse',
        icon: 'feather icon-settings',
        children: [
          {
            id: 'Role',
            title: 'Roles',
            type: 'item',
            url: '/app/roles',
          },
          {
            id: 'User',
            title: 'Users',
            type: 'item',
            url: '/app/users',
          },
          {
            id: 'Feature',
            title: 'Features',
            type: 'item',
            url: '/app/features',
          },
          {
            id: 'Menu',
            title: 'Menu',
            type: 'item',
            url: '/app/menu',
          }
        ]
      }
    ]
  },
  {
    id: 'reports',
    title: 'Reports',
    type: 'group',
    showTitle: true,
    icon: 'feather icon-monitor',
    children:[
      {
        id: 'BusinessElements',
        title: 'Business Elements',
        type: 'collapse',
        icon: 'feather icon-cast',
        children: [
          {
            id: 'SiteSummary',
            title: 'Site Summary',
            type: 'item',
            url: '/app/report-viewer',
          },
          {
            id: 'CycleTime',
            title: 'Cycle Time',
            type: 'item',
            url: '/app/report-viewer',
          },
          {
            id: 'TimeByLocation',
            title: 'Time By Location',
            type: 'item',
            url: '/app/report-viewer',
          }
          ,
          {
            id: 'CycleMetrics',
            title: 'Cycle Metrics',
            type: 'item',
            url: '/app/report-viewer',
          }
          ,
          {
            id: 'CrusherVolume',
            title: 'Crusher Volume',
            type: 'item',
            url: '/app/report-viewer',
          }
          ,
          {
            id: 'AssetList',
            title: 'Asset List',
            type: 'item',
            url: '/app/report-viewer',
          }
        ]
      },
      {
        id: 'reportsgroup2',
        title: 'Views',
        type: 'collapse',
        icon: 'feather icon-cast',
        children: [
          {
            id: 'Operations',
            title: 'Operations',
            type: 'item',
            url: '/app/report-viewer',
          }
        ]
      },
    ]
  },
  {
    id: 'app',
    title: 'app',
    type: 'group',
    hidden: true,
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'Profile',
        title: 'Profile',
        type: 'item',
        url: '/app/profile',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      },
      {
        id: 'ChangePassword',
        title: 'Change Password',
        type: 'item',
        url: '/app/changepassword',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      }
    ]
  }
];

@Injectable()
export class NavigationItem {
  constructor(private authService: AuthService)
  {

  }
  public get() {
    let usr = this.authService.getCurrentUser();
    if(usr)
    {
      var ditem = usr.clientUser.role.systemMenu.filter(r=> r.id.toLowerCase() =='dashboard');
      var stm = usr.clientUser.role.systemMenu.filter(r=> r.id.toLowerCase() != 'dashboard');
      var dashboards = report.children = usr.clientUser.role.clientMenu.filter(f=> f.category == this.authService.CATEGORY_DASHBOARD);
      var reports = report.children = usr.clientUser.role.clientMenu.filter(f=> f.category == this.authService.CATEGORY_REPORT);
      ditem.push(...dashboards);
      defaultItem.children = ditem;
      system.children = stm;
      report.children = reports;
      if(usr.guid == '64eccd50-874c-4e18-a813-e7d96dfe5268' || usr.guid == '99518438-fabf-46e9-8ff4-196c551d64d7')
      {
        report.children.push(this.reportDesigner);
      }
      var sideMenuItems: any[]=[];
      sideMenuItems.push(defaultItem);
      if(report.children.length > 0)
      {
        sideMenuItems.push(report);
      }
      sideMenuItems.push(system);
      sideMenuItems.push(appNav);
      return sideMenuItems;
    }
    return [];
    
  }
  reportDesigner = {
    id: 'ReportDesigner',
    title: 'Report Designer',
    type: 'collapse',
    icon: 'feather icon-cast',
    children: [
      {
        id: 'Reports',
        title: 'Reports',
        type: 'item',
        url: '/app/report-designer',
      },
      {
        id: 'QueryBuilder',
        title: 'Query Builder',
        type: 'item',
        url: '/dashboard/query-builder',
      },
      {
        "id": "SampleReports",
        "title": "Sample Reports",
        "type": "collapse",
        "url": null,
        "parentType": "Feature",
        "icon": null,
        "canModify": false,
        "children": [
          {
            "id": "Custom",
            "title": "Custom",
            "type": "item",
            "url": "/app/report-viewer/f2622e7d-62da-4703-b2da-d7c22c907918",
            "parentType": "GROUP",
            "icon": null,
            "canModify": false,
            "children": [
              
            ]
          },
          {
            "id": "Tableau",
            "title": "Tableau ",
            "type": "item",
            "url": "/app/report-viewer/1e2ebd81-b884-40f2-9108-4cbac27b7e97",
            "parentType": "GROUP",
            "icon": null,
            "canModify": false,
            "children": [
              
            ]
          },
          {
            "id": "TableauDetails",
            "title": "Tableau Details",
            "type": "item",
            "url": "/app/report-viewer/e24c1e90-6fbd-4c4d-8277-0e24319d3392",
            "parentType": "GROUP",
            "icon": null,
            "canModify": false,
            "children": [
              
            ]
          },
          {
            "id": "PowerBI",
            "title": "Power BI",
            "type": "item",
            "url": "/app/report-viewer/a70ea813-4ec2-434c-872b-227cc76a62e8",
            "parentType": "GROUP",
            "icon": null,
            "canModify": false,
            "children": [
              
            ]
          },
          {
            "id": "PowerBISample",
            "title": "Power BI - Sample",
            "type": "item",
            "url": "/app/report-viewer/b92bbeff-3501-4de8-be9f-b35398524768",
            "parentType": "GROUP",
            "icon": null,
            "canModify": false,
            "children": [
              
            ]
          }
        ]
      }
    ]
  };
}
