import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';
import 'rxjs/add/operator/catch';
import { throwError, of } from 'rxjs';
import { Router } from '@angular/router';
import { IfStmt } from '@angular/compiler';
import { catchError } from 'rxjs/operators';
import { DataTablesResponse } from '../models/DataTablesResponse.model';


@Injectable({
    providedIn: 'root'
})

export class ClientService {
    constructor(private httpClient: HttpClient, private router: Router) {

    }

    public getAll() {
        return this.httpClient.get(environment.clientApi + '/getAll');
    }
    public getAllIntegration() {
        return this.httpClient.get(environment.integrationApi + '/getAllActive');
    }
    public getAllClientTypes() {
        return this.httpClient.get(environment.apibaseUrl + '/clientType/getAll');
    }
    public get(guid) {
        return this.httpClient.get(environment.clientApi + '/get?guid='+guid);
    }
    public getClientDetails() {
        return this.httpClient.get(environment.clientApi + '/startup');
    }
    public save(item: any) {
        return this.httpClient.post<any>(environment.clientApi + '/save',
            item, { headers: new HttpHeaders().set('Content-Type', 'application/json') });
    }
    public Upload(file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('isAsset', 'true');   
        return this.httpClient.post<any>(environment.apibaseUrl + '/upload/profile',
            formData);
    }
}