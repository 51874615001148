export class ClientAttribute 
{
    static AUTH0_ENABLED: string = "auth0_enabled";
    static AUTH0_DOMAIN: string = "auth0_domain";
    static AUTH0_CLIENT_ID: string = "auth0_client_id";

    guid: string;
    clientGuid: string;
    name: string;
    value: string;
    description: string;
    createdDate: any;
    createdBy: string;
    updatedDate: any;
    updateBy: string;
    constructor() {
    }
}
