<perfect-scrollbar *ngIf="this.flatConfig['layout'] === 'vertical'" (clickOutside)="navMob()" [excludeBeforeClick]="true" [exclude]="'#mobile-collapse'" id="nav-ps-flat-able" ngClass="flat-able-scroll" [style.max-width]="'300px'" [style.max-height]="'calc(100vh - 56px)'"
    [usePSClass]="'flat-able'" [disabled]="null">
    <div class="navbar-content">
        <div class="">
            <div class="main-menu-header">
                <img class="img-radius" src="assets/images/user/user_default.png" alt="User-Profile-Image">
                <div class="user-details" (click)="isNavProfile = !isNavProfile">
                    <span>{{userName}}</span>
                    <div id="more-details">{{userRole}}<i class="fa m-l-5" [ngClass]="{'fa-chevron-up': isNavProfile, 'fa-chevron-down': !isNavProfile }"></i></div>
                </div>
            </div>
            <div class="collapse" id="nav-user-link" *ngIf="isNavProfile" [style.display]="'block'">
                <ul class="list-inline">
                    <li class="list-inline-item"><a href="javascript:" title="edit profile" [routerLink]="['./app/profile']"><i class="feather icon-user m-r-5"></i></a></li>
                    <li class="list-inline-item"><a href="javascript:" class="text-danger" title="change password" [routerLink]="['./app/changepassword']"><i class="feather icon-pocket m-r-5"></i></a></li>
                    <!-- <li class="list-inline-item"><a href="javascript:" style="visibility: hidden;" ><i class="feather icon-mail m-r-5"></i><small class="badge badge-pill badge-primary">5</small></a></li>-->
                </ul>
            </div>
        </div>
        <ul class="nav pcoded-inner-navbar" (clickOutside)="fireOutClick()">
            <ng-container *ngFor="let item of navigation">
                <app-nav-group *ngIf="item.type=='group'" [item]="item"></app-nav-group>
                <app-nav-collapse *ngIf="item.type=='collapse'" [item]="item"></app-nav-collapse>
                <app-nav-item *ngIf="item.type=='item'" [item]="item"></app-nav-item>
            </ng-container>
        </ul>
    </div>
    <div id="copyright" class="text-center" style="display: none; z-index: 1000; background: #fff;color:#fc5201; font-weight: bold; position: absolute; bottom: 0px;left: 0; right: 0; margin-left: auto; margin-right: auto;">
        Copyright © 2021 <a style="color:#fc5201; " target="_blank" href="https://Inwirement.com/">Inwirement</a>
    </div>
</perfect-scrollbar>

<div *ngIf="this.flatConfig['layout'] === 'horizontal'" #navbarWrapper class="navbar-content sidenav-horizontal" id="layout-sidenav">
    <a href="javascript:" class="sidenav-horizontal-prev" [ngClass]="prevDisabled" (click)="scrollMinus()"></a>
    <div class="sidenav-horizontal-wrapper">
        <!-- add 14-4 viral -->
        <ul #navbarContent id="side-nav-horizontal" class="nav pcoded-inner-navbar sidenav-inner" (clickOutside)="fireLeave()" (mouseleave)="fireLeave()">
            <ng-container *ngFor="let item of navigation">
                <app-nav-group *ngIf="item.type=='group'" [item]="item"></app-nav-group>
                <app-nav-collapse *ngIf="item.type=='collapse'" [item]="item"></app-nav-collapse>
                <app-nav-item *ngIf="item.type=='item'" [item]="item"></app-nav-item>
            </ng-container>
        </ul>
    </div>
    <a href="javascript:" class="sidenav-horizontal-next" [ngClass]="nextDisabled" (click)="scrollPlus()"></a>
</div>