import {Component, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../../../../../services/auth.service';
import {UserService} from '../../../../../services/user.service';
import {SiteService} from '../../../../../services/site.service';
import { TranslateService } from '@ngx-translate/core';
import { Site } from 'src/app/models/site.model'
import { User } from 'src/app/models/user.model';
import { user } from 'src/app/store/selectors';
@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {
  public themeType: string; // theme type
  public layoutType: string; // layout type
  public isConfig: boolean;
  public flatConfig: any;
  allSites: Site[]= [];
  selectedSites: number = 0;
  allSelected: boolean = false;
  constructor(private authService: AuthService, public translate: TranslateService,
    private siteService: SiteService,
    private userService: UserService) { 
    
  }
  logout()
  {
    this.authService.SignOut();
  }
   getSites()
    {
      this.siteService.getAll()
      .subscribe(resp => {
        if (resp && resp['code'] === 200 && resp['data']) {
        this.allSites = resp["data"];
        this.selectedSites = this.allSites.filter(r=> r.isSelected == true).length;
        var usr: User = this.authService.getCurrentUser();
        var userSelectedSites = sessionStorage.getItem("defaultVenues");
         
        if(usr && usr.clientUser && usr.clientUser.selectedSiteCsv)
        {
          var selSiteList: string[] = usr.clientUser.selectedSiteCsv.split(',');
         if(userSelectedSites!= undefined && userSelectedSites != null)
          {
            var userSelectedSiteList : any[] = JSON.parse(userSelectedSites);
            if(userSelectedSiteList.length > 0)
            {
              selSiteList = [];
              userSelectedSiteList.forEach(c => {
                selSiteList.push(c.guid);
              });
            }

          }
          
          this.allSites.forEach(s => {
            if(selSiteList.includes(s.guid))
            {
              s.isSelected = true;
            }
            else
            {
              s.isSelected = false;
            }
          });
          this.userService.userSelectedSitesCSV = usr.clientUser.selectedSiteCsv;
          this.selectedSites = this.allSites.filter(r=> r.isSelected == true).length;
          
        }
        else if(userSelectedSites!= undefined && userSelectedSites != null)
        {
          var userSelectedSiteList : any[] = JSON.parse(userSelectedSites);
          if(userSelectedSiteList.length > 0)
          {
            selSiteList = [];
            userSelectedSiteList.forEach(c => {
              selSiteList.push(c.guid);
            });
            this.allSites.forEach(s => {
              if(selSiteList.includes(s.guid))
              {
                s.isSelected = true;
              }
              else
              {
                s.isSelected = false;
              }
            });
            this.userService.userSelectedSitesCSV = selSiteList.join(",");
            this.selectedSites = this.allSites.filter(r=> r.isSelected == true).length;
          }

        }
        if(this.selectedSites == 0 && this.allSites.length == 1)
        {
          //if there is one site and user dont have any selected site, select that one
          this.allSites[0].isSelected = true;
          this.selectedSites = this.allSites.filter(r=> r.isSelected == true).length;
          this.saveSelectedSites();    
        }
      }
      }, error => {
        //this.errorNotifierService.notifyHttpError(error, 'api.getall');
      }
      );
    }
  ngOnInit() {  
    document.querySelector('body').classList.add('theme-1');
      document.querySelector('.pcoded-navbar').classList.add('menu-light');
      document.querySelector('body').classList.remove('flat-able-dark');
      this.SetUserPreference();
      this.getSites();
   }
   switchLang(lang: string) {
    this.translate.use(lang);
   }
   siteSelectionChange(site: Site)
   {
    this.selectedSites = this.allSites.filter(r=> r.isSelected == true).length;
    this.saveSelectedSites();
   }
   saveSelectedSites()
   {
     var selSites = this.allSites.filter(s=> s.isSelected);
     var siteCSV = '';
     var idx = 0;
     if(selSites.length > 0)
     {
        selSites.forEach(s => {
          if(idx >0)
          {
            siteCSV = siteCSV + ',';  
          }
          siteCSV = siteCSV + s.guid;
          idx++;
        });
        this.userService.saveUserSelectedSites(siteCSV)
        .subscribe(resp => {
          if (resp && resp['code'] === 200 && resp['data']) {
          }
        }, error => {
          //this.errorNotifierService.notifyHttpError(error, 'api.getall');
        }
        );
     }
     this.userService.saveUserSelectedSites(siteCSV)
        .subscribe(resp => {
          if (resp && resp['code'] === 200 && resp['data']) {
          }
        }, error => {
          //this.errorNotifierService.notifyHttpError(error, 'api.getall');
        }
        );
   }
   saveSelectedlayout(layout: string)
   {
        this.userService.saveUserSelectedLayout(layout)
        .subscribe(resp => {
          if (resp && resp['code'] === 200 && resp['data']) {
          }
        }, error => {
          //this.errorNotifierService.notifyHttpError(error, 'api.getall');
        }
        );
  }
   saveSelectedTheme(theme: string)
   {
        this.userService.saveUserSelectedTheme(theme)
        .subscribe(resp => {
          if (resp && resp['code'] === 200 && resp['data']) {
          }
        }, error => {
          //this.errorNotifierService.notifyHttpError(error, 'api.getall');
        }
        );
    }
   siteSelectAll()
   {
     this.allSites.forEach(element => {
       element.isSelected = this.allSelected;
     });
     this.selectedSites = this.allSites.filter(r=> r.isSelected == true).length;    
     this.saveSelectedSites();
   }
  allThemes = ['theme-1','theme-2','theme-3','theme-4','theme-5','theme-6','theme-default'];
  allLayouts=['layout-1','layout-2','layout-3'];
  setTheme(theme) {
    this.isConfig = true;
    document.querySelector('body').classList.remove('theme-1');
    document.querySelector('body').classList.remove('theme-2');
    document.querySelector('body').classList.remove('theme-3');
    document.querySelector('body').classList.remove('theme-default');
    document.querySelector('body').classList.remove('theme-4');
    document.querySelector('body').classList.remove('theme-5');
    document.querySelector('body').classList.remove('theme-6');
    document.querySelector('.pcoded-navbar').classList.remove('menu-light');
    document.querySelector('body').classList.remove('flat-able-dark');

    this.themeType = theme;
    if (theme === 'theme-1') {
      document.querySelector('body').classList.add('theme-1');
      document.querySelector('.pcoded-navbar').classList.add('menu-light');
      document.querySelector('.default-theme').classList.add('active');
    }
    if (theme === 'theme-2') {
      document.querySelector('body').classList.add('theme-2');
      document.querySelector('.pcoded-navbar').classList.add('menu-light');
      document.querySelector('.default-theme').classList.remove('active');
    }
    if (theme === 'theme-3') {
      document.querySelector('body').classList.add('theme-3');
      document.querySelector('.pcoded-navbar').classList.add('menu-light');
      document.querySelector('.default-theme').classList.remove('active');
    }
    if (theme === 'theme-4') {
      document.querySelector('body').classList.add('theme-4');
      document.querySelector('.pcoded-navbar').classList.add('menu-light');
      document.querySelector('.default-theme').classList.remove('active');
    }
    if (theme === 'theme-5') {
      document.querySelector('body').classList.add('theme-5');
      document.querySelector('.pcoded-navbar').classList.add('menu-light');
      document.querySelector('.default-theme').classList.remove('active');
    }
    if (theme === 'theme-6') {
      document.querySelector('body').classList.add('theme-6');
      document.querySelector('.pcoded-navbar').classList.add('menu-light');
      document.querySelector('.default-theme').classList.remove('active');
    }
    if (theme === 'theme-default') {
      document.querySelector('body').classList.remove('theme-1');
      document.querySelector('body').classList.add('theme-default');
      document.querySelector('.pcoded-navbar').classList.add('menu-light');
      document.querySelector('.default-theme').classList.remove('active');
    }
    //localStorage.setItem('UserTheme',theme);
    this.saveSelectedTheme(theme);
    // if (theme === 'default') {
    //   this.default();
    // }
  }
  // default() {
  //   document.querySelector('body').classList.remove('theme-1');
  //   document.querySelector('body').classList.remove('theme-2');
  //   document.querySelector('body').classList.remove('theme-3');
  //   document.querySelector('.pcoded-navbar').classList.add('menu-light');
  // }

  setLayout(layout) {
    this.isConfig = true;
    this.layoutType = layout;
    if (layout === 'layout-1') {
      document.querySelector('.pcoded-header').classList.remove('headerpos-fixed');
      document.querySelector('.pcoded-navbar').classList.add('menupos-static');
      document.querySelector('.pcoded-navbar').classList.remove('menupos-fixed');
      document.querySelector('body').classList.remove('container');
      document.querySelector('body').classList.remove('box-layout');
      document.querySelector('.default-layout').classList.remove('active');
    } 
  
    if (layout === 'layout-2') {
      document.querySelector('.pcoded-header').classList.add('headerpos-fixed');
      document.querySelector('.pcoded-navbar').classList.remove('menupos-static');
      document.querySelector('.pcoded-navbar').classList.add('menupos-fixed');
      document.querySelector('body').classList.remove('container');
      document.querySelector('body').classList.remove('box-layout');
      document.querySelector('.default-layout').classList.add('active');
    }
  
    if (layout === 'layout-3') {
      document.querySelector('body').classList.add('container');
      document.querySelector('body').classList.add('box-layout');
      document.querySelector('.pcoded-navbar').classList.remove('menupos-static');
      document.querySelector('.pcoded-navbar').classList.remove('menupos-fixed');
      document.querySelector('.default-layout').classList.remove('active');
      // this.flatConfig['box-layout'] = true;
    } 
    //localStorage.setItem('UserLayout',layout);
    this.saveSelectedlayout(layout);
  }
  SetUserPreference()
  {
    var usr: User = this.authService.getCurrentUser();
    if(usr && usr.clientUser)
    {
        if(usr.clientUser.selectedTheme)
        {
          if(this.allThemes.includes(usr.clientUser.selectedTheme))
          {
            this.setTheme(usr.clientUser.selectedTheme);
          }
        }
        if(usr.clientUser.selectedLayout)
        {
          if(this.allLayouts.includes(usr.clientUser.selectedLayout))
          {
            this.setLayout(usr.clientUser.selectedLayout);
          }
        }
      }
  } 
}




