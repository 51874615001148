
import { ActionReducerMap, Action } from '@ngrx/store';
import { routerReducer } from '@ngrx/router-store';
import { IAppState } from '../states';
import {  authReducers} from '../reducers/auth.reducer';

export const appReducers: ActionReducerMap<IAppState, any> = {
    router: routerReducer,
    authState: authReducers,
};

export class ActionTypes {
    static CLEAR_APP_STATE = "[App] Clear App State";
}

export class ClearAppState implements Action {
    readonly type = ActionTypes.CLEAR_APP_STATE;
}

export function clearState(reducer) {
    return function (state, action) {

        if (action.type === ActionTypes.CLEAR_APP_STATE) {
            state = undefined;
        }

        return reducer(state, action);
    };
}