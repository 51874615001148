import {Component, Input, NgZone, OnInit} from '@angular/core';
import {NavigationItem} from '../../navigation';
import {NextConfig} from '../../../../../../app-config';
import {AuthService} from '../../../../../../services/auth.service';
import {Location} from '@angular/common';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss']
})
export class NavItemComponent implements OnInit {
  @Input() item: NavigationItem;
  public flatConfig: any;
  public themeLayout: string;

  constructor(private location: Location, private authService: AuthService) {
    this.flatConfig = NextConfig.config;
    this.themeLayout = this.flatConfig['layout'];
  }

  ngOnInit() {
  }

  isGroupMenu(usr: User, title: string){
    usr.clientUser.role.systemMenu.forEach(m => {
      var isAtdefaultLevel =  m.children.filter(c=> c.title == title);
      if(isAtdefaultLevel.length > 0)
      {
        return false;
      }
    });
    usr.clientUser.role.clientMenu.forEach(m => {
      var isAtdefaultLevel =  m.children.filter(c=> c.title == title);
      if(isAtdefaultLevel.length > 0)
      {
        return false;
      }
    });
  }

  closeOtherMenu(event) {
    var usr: User = this.authService.getCurrentUser();
    if (this.flatConfig['layout'] === 'vertical') {
      const ele = event.target;
      if (ele !== null && ele !== undefined) {
        const parent = ele.parentElement;
        const up_parent = parent.parentElement.parentElement;
        const last_parent = up_parent.parentElement;
        const last_parent4 = last_parent.parentElement;
        const last_parent3 = last_parent4.parentElement;
        const last_parent2 = last_parent3.parentElement;
        const last_parent1 = last_parent2.parentElement;
        
        const sections = document.querySelectorAll('.pcoded-hasmenu');
        var isGroupItm = false;
        if(parent.dataset){
          if(parent.dataset.type){
            if(parent.dataset.type =='GROUP'){
              isGroupItm = true;
            }
          }
        }
        //if(!isGroupItm)
        //{
        for (let i = 0; i < sections.length; i++) {
          sections[i].classList.remove('active');
          sections[i].classList.remove('pcoded-trigger');
        }

        if (parent.classList.contains('pcoded-hasmenu')) {
          parent.classList.add('pcoded-trigger');
          parent.classList.add('active');
        } else if (up_parent.classList.contains('pcoded-hasmenu')) {
          up_parent.classList.add('pcoded-trigger');
          up_parent.classList.add('active');
        } else if (last_parent.classList.contains('pcoded-hasmenu')) {
          last_parent.classList.add('pcoded-trigger');
          last_parent.classList.add('active');
        }

        if (last_parent4.classList.contains('pcoded-hasmenu')) {
          last_parent4.classList.add('pcoded-trigger');
          last_parent4.classList.add('active');
        }
        if (last_parent3.classList.contains('pcoded-hasmenu')) {
          last_parent3.classList.add('pcoded-trigger');
          last_parent3.classList.add('active');
        }
        if (last_parent2.classList.contains('pcoded-hasmenu')) {
          last_parent2.classList.add('pcoded-trigger');
          last_parent2.classList.add('active');
        }
        if (last_parent1.classList.contains('pcoded-hasmenu')) {
          last_parent1.classList.add('pcoded-trigger');
          last_parent1.classList.add('active');
        }
      //}
      if ((document.querySelector('app-navigation.pcoded-navbar').classList.contains('mob-open'))) {
        document.querySelector('app-navigation.pcoded-navbar').classList.remove('mob-open');
      }
    }
    } else {
      setTimeout(() => {
        const sections = document.querySelectorAll('.pcoded-hasmenu');
        for (let i = 0; i < sections.length; i++) {
          sections[i].classList.remove('active');
          sections[i].classList.remove('pcoded-trigger');
        }

        let current_url = this.location.path();
        if (this.location['_baseHref']) {
          current_url = this.location['_baseHref'] + this.location.path();
        }
        const link = "a.nav-link[ href='" + current_url + "' ]";
        const ele = document.querySelector(link);
        if (ele !== null && ele !== undefined) {
          const parent = ele.parentElement;
          const up_parent = parent.parentElement.parentElement;
          const last_parent = up_parent.parentElement;
          if (parent.classList.contains('pcoded-hasmenu')) {
            parent.classList.add('active');
          } else if (up_parent.classList.contains('pcoded-hasmenu')) {
            up_parent.classList.add('active');
          } else if (last_parent.classList.contains('pcoded-hasmenu')) {
            last_parent.classList.add('active');
          }
        }
      }, 500);
    }
  }

}
