import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';
import { AngularFireAuthGuard,redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { AuthenticationGuardService } from './guard/AuthenticationGuardService';
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/auth/signin-v2']);
import { AuthGuardServiceForLoggedIn } from './guard/AuthGuardServiceForLoggedIn';
import { HomeComponent } from './components/home/home.component';
const routes: Routes = [
  { path: '', component: HomeComponent },
{ path: 'client',  component: HomeComponent },
{ path: 'client/:clientId', redirectTo: 'client/:clientId/auth/signin-v2', pathMatch: 'full' },
{
  path: 'client/:clientId',
  component: AdminComponent,
  canActivate: [AuthenticationGuardService],
  //data: { authGuardPipe: redirectUnauthorizedToLogin },
  children: [
    {
      path: '',
      //redirectTo: 'dashboard/default',
      //pathMatch: 'full',
      loadChildren: () => import('./components/dashboard/dashboard.module').then(module => module.DashboardModule)
    },
    {
      path: 'app',
      loadChildren: () => import('./components/vpapp/vpapp.module').then(module => module.VpAppModule)
    },
    {
      path: 'dashboard',
      loadChildren: () => import('./components/dashboard/dashboard.module').then(module => module.DashboardModule)
    }
  ],
  
},
{
  path: 'client/:clientId',
  component: AuthComponent,
  canActivate: [AuthGuardServiceForLoggedIn],
  children: [
    {
      path: 'auth',
      loadChildren: () => import('./components/authentication/authentication.module').then(module => module.AuthenticationModule)
    }
  ]
},
{ path: '**', redirectTo: '', pathMatch: 'full' },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
