import { createSelector } from '@ngrx/store';
import { IAuthState } from '../states/auth.state';
import { IAppState } from '../states';

const authState = (state: IAppState) => state.authState;

export const get = createSelector(
    authState,
    (state: IAuthState) => state.get
);

export const authError = createSelector(
    authState,
    (state: IAuthState) => state.authError
);

export const loading = createSelector(
    authState,
    (state: IAuthState) => state.loading
);

export const user = createSelector(
    authState,
    (state: IAuthState) => state.user
);