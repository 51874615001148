<ul class="navbar-nav ml-auto">
  <li style="display:none">
    <a href="https://github.com/phoenixcoded/flat-able-free-angular-admin-template" target="_blank" class="">
      <i class="icon feather icon-github pulse-button"></i>
    </a>
  </li>
  <li style="display:none">{{'Sitetitle' | translate }}</li>
  <li style="display:none">
  <select 
      class="form-control" 
      #selectedLang 
      (change)="switchLang(selectedLang.value)">
    <option *ngFor="let language of translate.getLangs()" 
      [value]="language"
      [selected]="language === translate.currentLang">
      {{ language }}
    </option>
  </select>
  </li>
  <!--<li>
   
     <div class="dropdown" ngbDropdown placement="auto">
      <a ngbDropdownToggle href="javascript:">
        <i class="icon feather icon-bell"></i>
        <span class="badge badge-pill badge-danger">5</span>
      </a>
      <div ngbDropdownMenu class="dropdown-menu-right notification">
        <div class="noti-head">
          <h6 class="d-inline-block m-b-0">Notifications</h6>
          <div class="float-right">
            <a href="javascript:" class="m-r-10">mark as read</a>
            <a href="javascript:">clear all</a>
          </div>
        </div>
        <ul class="noti-body">
          <li class="n-title">
            <p class="m-b-0">NEW</p>
          </li>
          <li class="notification">
            <div class="media">
              <img class="img-radius" src="assets/images/user/avatar-1.jpg" alt="Generic placeholder image">
              <div class="media-body">
                <p><strong>John Doe</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>30 min</span></p>
                <p>New ticket Added</p>
              </div>
            </div>
          </li>
          <li class="n-title">
            <p class="m-b-0">EARLIER</p>
          </li>
          <li class="notification">
            <div class="media">
              <img class="img-radius" src="assets/images/user/avatar-2.jpg" alt="Generic placeholder image">
              <div class="media-body">
                <p><strong>Joseph William</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>30 min</span></p>
                <p>Prchace New Theme and make payment</p>
              </div>
            </div>
          </li>
          <li class="notification">
            <div class="media">
              <img class="img-radius" src="assets/images/user/avatar-3.jpg" alt="Generic placeholder image">
              <div class="media-body">
                <p><strong>Sara Soudein</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>30 min</span></p>
                <p>currently login</p>
              </div>
            </div>
          </li>
        </ul>
        <div class="noti-footer">
          <a href="javascript:">show all</a>
        </div>
      </div>
    </div> 
    
  </li>-->
  <li>
    <div class="dropdown" ngbDropdown placement="auto">
      <a ngbDropdownToggle href="javascript:">
        <i class="icon feather icon-map"></i>
        <span class="badge badge-pill badge-danger" style="right:-18px">{{selectedSites}}</span>
      </a>
      <div ngbDropdownMenu class="dropdown-menu-right style-block profile-notification" style="width: 220px;">
        <ul class="pro-body">
          <p class="ml-3 mb-2"><b>VENUES</b></p>
          <li style="padding-bottom: 10px; padding-left: 15px; border-bottom: 1px solid rgba(0, 0, 0, 0.15); border-top: 1px solid rgba(0, 0, 0, 0.15);">
            <span style="width:30px; display: inline">
              <div class="switch switch-primary d-inline m-r-10"  style="width: 30px;">
                <input (change)="siteSelectAll()" type="checkbox" id="all" name="all" [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}">
                <label for="all" class="cr"></label>
              </div>
            </span>
            <a  style="width:155px; display: inline; padding:0px; cursor:default" href="javascript:">All</a>
          </li>
            <li *ngFor="let s of allSites" style="padding-bottom: 10px; padding-left: 15px">
              <span style="width:30px; display: inline">
                <div class="switch switch-primary d-inline m-r-10"  style="width: 30px;">
                  <input (change)="siteSelectionChange(s)" type="checkbox" [(id)]="'assigned-' + s.guid" [(name)]="'assigned-' + s.guid" [(ngModel)]="s.isSelected" [ngModelOptions]="{standalone: true}">
                  <label [(for)]="'assigned-' + s.guid" class="cr"></label>
                </div>
              </span>
              <a  style="width:155px; display: inline; padding:0px; cursor:default" href="javascript:">{{s.name}}
                <span *ngIf="!s.isActive" class="badge badge-pill badge-danger" style="position: relative;top: -3px;">Inactive</span>
              </a>
            </li>
          </ul>
      </div>
    </div>
  </li>  
  <li>
    <div class="dropdown" ngbDropdown placement="auto">
      <a ngbDropdownToggle href="javascript:">
        <i class="icon feather icon-settings"></i>
      </a>
      <div ngbDropdownMenu class="dropdown-menu-right style-block profile-notification">
        <ul class="pro-body">
          <p class="ml-3 mb-2"><b>LAYOUTS</b></p>
          <li><a href="javascript:" [ngClass]="{'active': layoutType === 'layout-1'}" (click)="setLayout('layout-1')" data-value="layout-1" class="dropdown-item"><img class="theme-icon mr-2" src="..\..\..\..\..\..\assets\images\casual.png"/>Casual Layout</a></li>
          <li><a href="javascript:"  (click)="setLayout('layout-2')" data-value="layout-2" class="dropdown-item active default-layout"><img class="theme-icon mr-2" src="..\..\..\..\..\..\assets\images\professional.png"/>Professional Layout</a></li>
          <li><a href="javascript:" [ngClass]="{'active': layoutType === 'layout-3'}" (click)="setLayout('layout-3')" data-value="layout-3" class="dropdown-item"><img class="theme-icon mr-2" src="..\..\..\..\..\..\assets\images\corporate.png"/>Corporate Layout</a></li> 
          <p class="ml-3 mt-3 mb-2"><b>THEMES</b></p>
          <li><a href="javascript:"  (click)="setTheme('theme-1')" data-value="theme-1" class="dropdown-item active default-theme"><img class="theme-icon mr-2" src="..\..\..\..\..\..\assets\images\theme-1.png"/> Subdued & Professional</a></li>
          <li><a href="javascript:" [ngClass]="{'active': themeType === 'theme-2'}" (click)="setTheme('theme-2')" data-value="theme-2" class="dropdown-item"><img class="theme-icon mr-2" src="..\..\..\..\..\..\assets\images\theme-2.png"/>Cool Blues</a></li>
          <li><a href="javascript:" [ngClass]="{'active': themeType === 'theme-3'}" (click)="setTheme('theme-3')" data-value="theme-3" class="dropdown-item"><img class="theme-icon mr-2" src="..\..\..\..\..\..\assets\images\theme-3.png"/>Crisp & Dramatic</a></li>
          <li><a href="javascript:" [ngClass]="{'active': themeType === 'theme-default'}" (click)="setTheme('theme-default')" data-value="theme-default" class="dropdown-item"><img class="theme-icon mr-2" src="..\..\..\..\..\..\assets\images\theme-default.png"/>Default</a></li>
          <li><a href="javascript:" [ngClass]="{'active': themeType === 'theme-4'}" (click)="setTheme('theme-4')" data-value="theme-4" class="dropdown-item"><img class="theme-icon mr-2" src="..\..\..\..\..\..\assets\images\theme-4.png"/>Dark & Earthy</a></li>
          <li><a href="javascript:" [ngClass]="{'active': themeType === 'theme-5'}" (click)="setTheme('theme-5')" data-value="theme-5" class="dropdown-item"><img class="theme-icon mr-2" src="..\..\..\..\..\..\assets\images\theme-5.png"/>Autumn in Vermont</a></li>
          <li><a href="javascript:" [ngClass]="{'active': themeType === 'theme-6'}" (click)="setTheme('theme-6')" data-value="theme-6" class="dropdown-item"><img class="theme-icon mr-2" src="..\..\..\..\..\..\assets\images\theme-6.png"/>Warm Naturals</a></li>
        </ul>
      </div>
    </div>
  </li>
  <li>
      <a href="javascript:" (click)="logout()" class="dud-logout" title="Logout">
        <i class="feather icon-log-out"></i>
      </a>
  </li>
  <li style="display:none">
    <div class="drp-user dropdown" ngbDropdown placement="auto">
      <a href="javascript:" ngbDropdownToggle>
        <i class="feather icon-user"></i>
      </a>
      <div class=" dropdown-menu-right profile-notification" ngbDropdownMenu>
        <div class="pro-head">
          <img src="assets/images/user/avatar-1.jpg" class="img-radius" alt="User-Profile-Image">
          <span>John Doe</span>
          <a href="javascript:" (click)="logout()" class="dud-logout" title="Logout">
            <i class="feather icon-log-out"></i>
          </a>
        </div>
        <ul class="pro-body">
          <li><a href="javascript:" class="dropdown-item"><i class="feather icon-user"></i> Profile</a></li>
          <li><a href="javascript:" class="dropdown-item"><i class="feather icon-mail"></i> My Messages</a></li>
          <li><a href="javascript:" class="dropdown-item"><i class="feather icon-lock"></i> Lock Screen</a></li>
        </ul>
      </div>
    </div>
  </li>
</ul>
