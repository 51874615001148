// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const baseApiUrl = 'https://inwirement.americloudtelecom.com';

export const environment = {
  production: false,
  environmentName: 'local',
  firebaseConfig : {
    apiKey: "AIzaSyCyWh81fliMD3MpA1-rSPjEBxImP0SJI7c",
    authDomain: "inwirement.firebaseapp.com",
    projectId: "inwirement",
    storageBucket: "inwirement.appspot.com",
    messagingSenderId: "270898917939",
    appId: "1:270898917939:web:3f42493ebaf1904679a946",
    measurementId: "G-ZMZNRYK5DH"
  },
  DefaultbaseColor: "#F96302",
  DefaultbaseColorPaletteCSV: "#F96302,#ff6d0c,#ff7716,#ff8120,#ff8b2a,#ff9534,#ff9f3e,#ffa948,#ffb352,#ffbd5c",
  usCenterLat: 47.116386,
  usCenterLng: -95.712891,
  apibaseUrl: baseApiUrl,
  userApi: baseApiUrl + '/user',
  roleApi: baseApiUrl + '/role',
  dashboardApi: baseApiUrl+'/dashboard',
  categoryApi: baseApiUrl+'/category',
  featureApi: baseApiUrl + '/feature',
  filterApi: baseApiUrl + '/filter',
  featureUxApi: baseApiUrl + '/featureUx',
  featureGroupApi: baseApiUrl + '/featureGroup',
  featureDxApi: baseApiUrl + '/featureDx',
  clientApi: baseApiUrl + '/client',
  termAndConditionApi: baseApiUrl + '/clientTermAndCondition',
  siteApi: baseApiUrl + '/site',
  assetApi: baseApiUrl + '/assets',
  integrationApi: baseApiUrl + '/integration',
  defaultRoleId: "k5xjwq9I_O_j0KmXki2ILYTQ_R__R_",
  defaultClientId: "799904db-153a-11ec-9f7a-0ae1853f1c63",
  defaultClinetName: 'inwirement',
  reportAdapterApi: "http://adapter.americloud.net/adapters",
  reportApi: baseApiUrl + '/report',
  scheduleApi: baseApiUrl +'/schedule',
  dxApi: baseApiUrl + '/dx',
  notificationApi: baseApiUrl +'/notification',
  queryBuilderApi: baseApiUrl + '/query-builder'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
