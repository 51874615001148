import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';
import 'rxjs/add/operator/catch';
import { throwError, of } from 'rxjs';
import { Router } from '@angular/router';
import { IfStmt } from '@angular/compiler';
import { catchError } from 'rxjs/operators';
import { DataTablesResponse } from '../models/DataTablesResponse.model';
import { UserService } from './user.service';
import { MediaUpload } from '../models/mediaupload.model';

@Injectable({
    providedIn: 'root'
})

export class SiteService {
    constructor(private httpClient: HttpClient, private router: Router, private userService: UserService) {

    }

    public getAll() {
        //return this.httpClient.get('./assets/sites.json');
        return this.httpClient.get(environment.siteApi + '/getAll');
    }
    public get(guid) {
        //return this.httpClient.get('./assets/sites.json');
        return this.httpClient.get(environment.siteApi + '/get?guid='+guid);
    }
    public getVenueItemTree(siteGuid) {
        //return this.httpClient.get('./assets/sites.json');
        return this.httpClient.get(environment.siteApi + '/venueItem/getAll?siteGuid='+siteGuid);
    }
    public getVenueItem(guid) {
        //return this.httpClient.get('./assets/sites.json');
        return this.httpClient.get(environment.siteApi + '/venueItem/get?venueItemGuid='+guid);
    }
    public saveVenueItem(item: any) {
        return this.httpClient.post<any>(environment.siteApi + '/venueItem/save',
            item, { headers: new HttpHeaders().set('Content-Type', 'application/json') });
    }
    public save(item: any) {
        return this.httpClient.post<any>(environment.siteApi + '/save',
            item, { headers: new HttpHeaders().set('Content-Type', 'application/json') });
    }
    public saveAsset(item: any) {
        return this.httpClient.post<any>(environment.siteApi + '/assets/save',
            [item], { headers: new HttpHeaders().set('Content-Type', 'application/json') });
    }
    public getAssetTypes() {
        //return this.httpClient.get('./assets/sites.json');
        return this.httpClient.get(environment.apibaseUrl + '/assetType/getAll');
    }

    public getAllRegions(siteGuidCsv: string, loadDetails? : boolean) {
        //return this.httpClient.get('./assets/sites.json');
        if(loadDetails != undefined && loadDetails != null)
        {
            return this.httpClient.get(environment.siteApi + '/region/getAll?siteGuids='+siteGuidCsv+'&loadDetails='+loadDetails);    
        }
        return this.httpClient.get(environment.siteApi + '/region/getAll?siteGuids='+siteGuidCsv);
    }
    public getAllZones(siteGuidCsv: string, loadDetails? : boolean) {
        //return this.httpClient.get('./assets/sites.json');
        if(loadDetails != undefined && loadDetails != null)
        {
            return this.httpClient.get(environment.siteApi + '/zone/getAll?siteGuids='+siteGuidCsv+'&loadDetails='+loadDetails);    
        }
        return this.httpClient.get(environment.siteApi + '/zone/getAll?siteGuids='+siteGuidCsv);
    }
    public getAllRegionsForClient() {
        //return this.httpClient.get('./assets/sites.json');
        //return this.httpClient.get(environment.siteApi + '/region/getAllByClient');
        return this.httpClient.get(environment.siteApi + '/region/getAll');
    }
    public getAllAssets(guid) {
        //return this.httpClient.get('./assets/sites.json');
        return this.httpClient.get(environment.siteApi + '/assets/getAll?siteGuid='+guid);
    }
    public getRegion(guid) {
        //return this.httpClient.get('./assets/sites.json');
        return this.httpClient.get(environment.siteApi + '/region/get?guid='+guid);
    }
    public getAsset(guid) {
        //return this.httpClient.get('./assets/sites.json');
        return this.httpClient.get(environment.siteApi + '/assets/get?guid='+guid);
    }
    public saveRegion(item: any) {
        return this.httpClient.post<any>(environment.siteApi + '/region/save',
            item, { headers: new HttpHeaders().set('Content-Type', 'application/json') });
    }
    public saveShifts(item: any) {
        return this.httpClient.post<any>(environment.siteApi + '/shifts/save',
            [item], { headers: new HttpHeaders().set('Content-Type', 'application/json') });
    }
    
    public addIndoorRegionImages(image: MediaUpload)
    {
        return this.httpClient.post<any>(environment.apibaseUrl + '/media/save',
        image, { headers: new HttpHeaders().set('Content-Type', 'application/json') });
    }
    public deleteIndoorRegionImages(guid)
    {
        return this.httpClient.get(environment.apibaseUrl + '/media/delete?guid='+guid);
    }
    public venueImageUpload(file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('isAsset', 'true');   
        return this.httpClient.post<any>(environment.apibaseUrl + '/upload/profile',
            formData);
    }
}
