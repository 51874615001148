import {Component, Input, NgZone, OnInit} from '@angular/core';
import {NavigationItem} from '../../navigation';
import {Location} from '@angular/common';
import {NextConfig} from '../../../../../../app-config';

@Component({
  selector: 'app-nav-group',
  templateUrl: './nav-group.component.html',
  styleUrls: ['./nav-group.component.scss']
})
export class NavGroupComponent implements OnInit {
  @Input() item: any;
  @Input() layout1: boolean = false;
  @Input() activeId: any;
  public flatConfig: any;

  constructor(private zone: NgZone, private location: Location) {
    this.flatConfig = NextConfig.config;
  }

  ngOnInit() {
    // at reload time active and trigger link
    let current_url = this.location.path();
    if (this.location['_baseHref']) {
      current_url = this.location['_baseHref'] + this.location.path();
    }
    const link = "a.nav-link[ href='" + current_url + "' ]";
    const ele = document.querySelector(link);
    if (ele !== null && ele !== undefined) {
      const parent = ele.parentElement;
      const up_parent = parent.parentElement.parentElement;
      const last_parent = up_parent.parentElement;
      if (parent.classList.contains('pcoded-hasmenu')) {
        if (this.flatConfig['layout'] === 'vertical') {
          parent.classList.add('pcoded-trigger');
        }
        parent.classList.add('active');
      } else if(up_parent.classList.contains('pcoded-hasmenu')) {
        if (this.flatConfig['layout'] === 'vertical') {
          up_parent.classList.add('pcoded-trigger');
        }
        up_parent.classList.add('active');
      } else if (last_parent.classList.contains('pcoded-hasmenu')) {
        if (this.flatConfig['layout'] === 'vertical') {
          last_parent.classList.add('pcoded-trigger');
        }
        last_parent.classList.add('active');
      }
    }
    this.getNavItemsManually();
  }

  getNavItemsManually(){
    console.log(this.item);
    
    this.item.children.forEach((d)=>{
      if(d.id == "ReportDesigner"){
        d.children.push({
            id: 'default',
            title: 'Widget Designer',
            type: 'item',
            url: '/dashboard/query-builder'
          })
          d.children.push({
            id: 'Reports',
            title: 'Dashboards',
            type: 'item',
            url: '/dashboard/designer'
          })
          d.children.push({
            id: 'Reports',
            title: 'KPIs Definitions',
            type: 'item',
            url: '/dashboard/kpi-definitions-list'
          })
          
      }
       if(d.id == "Setup"){
        d.children.push({
            id: 'Setup',
            title: 'Source',
            type: 'item',
            url: '/app/source'
          })
      }
      
    });
  }

}
