import { Action } from '@ngrx/store';

export enum EAUTHActions {

    GET = 'GET',
    GET_SUCCESS = 'GET_SUCCESS',
    SET_LOADING = 'SET_LOADING',
    SET_LOADING_SUCCESS = 'SET_LOADING_SUCCESS',
    SET_USER = 'SET_USER',
    SET_USER_SUCCESS = 'SET_USER_SUCCESS',
}

export class Get implements Action {

    readonly type = EAUTHActions.GET;

}
export class SetLoading implements Action {
    readonly type = EAUTHActions.SET_LOADING;
}
export class SetUser implements Action {
    readonly type = EAUTHActions.SET_USER;
}

export class GetSuccess implements Action {

    readonly type = EAUTHActions.GET_SUCCESS;
    

    constructor(public payload: any, public error: any) {

    }

}
export class SetLoadingSuccess implements Action {

    readonly type = EAUTHActions.SET_LOADING_SUCCESS;
    

    constructor(public payload: boolean) {

    }

}
export class SetUserSuccess implements Action {

    readonly type = EAUTHActions.SET_USER_SUCCESS;
    

    constructor(public payload: any) {

    }

}


export type AuthActions =
    | Get
    | GetSuccess
    | SetLoading
    | SetLoadingSuccess
    | SetUser
    | SetUserSuccess;
