import {Component, Input, OnInit} from '@angular/core';
import {NavigationItem} from '../../../layout/admin/navigation/navigation';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input() type: string;

  public navigation: any;
  breadcrumbList: Array<any> = [];
  public navigationList: Array<any> = [];

  constructor(private route: Router, public nav: NavigationItem, private titleService: Title
    , private authService: AuthService) {
    this.navigation = this.nav.get();
    this.type = 'theme2';
    this.setBreadcrumb();
  }

  ngOnInit() {
    let routerUrl: string;
    routerUrl = this.route.url;
    if (routerUrl && typeof routerUrl === 'string') {
      this.filterNavigation(routerUrl);
    }
  }

  setBreadcrumb() {
    let routerUrl: string;
    let overrideNavs = [
      '/app/roles/add',
      '/app/roles/edit/',
      '/app/users/edit/',
      '/app/users/add',
      '/app/clients/add',
      '/app/clients/edit/',
      '/app/features/edit/',
      '/app/features/add',
      '/app/venues/add',
      '/app/venues/edit/',
      '/app/venues/venue-details/',
      '/app/venueregions/',
      '/app/venueassets/',
      '/app/report-designer/preview/',
      '/app/report-designer/add',
      '/app/report-designer/edit/',
      '/app/report-schedules/add',
      '/app/report-schedules/edit/',
      '/dashboard/'
    ]
    this.route.events.subscribe((router: any) => {
      routerUrl = router.urlAfterRedirects;
      if (routerUrl && typeof routerUrl === 'string') {
        this.breadcrumbList.length = 0;
        var activeLink = router.url;
        if(activeLink.startsWith('/client/'))
        {
          var idx = this.getPosition(activeLink, '/', 3);
          activeLink = activeLink.substring(idx, activeLink.length);
        }
        var navs = overrideNavs.filter(n=> activeLink.startsWith(n));
        if(navs.length>0)
        {
          this.overrideNav(activeLink);
        }
        else
        {    
          this.filterNavigation(activeLink);
        }
      }
    });
  }
  getPosition(string, subString, index) {
    return string.split(subString, index).join(subString).length;
  }
  overrideNav(activeLink)
  {
    var cClient = this.authService.getCurrentClient();
    var cName = '';
    if(cClient && cClient.name)
    {
      cName = cClient.name + ' | ';
    }

    if(activeLink.startsWith('/dashboard/'))
    {
      this.navigationList =[];
      this.titleService.setTitle(cName +' Dashboard');
    }
    else if(activeLink == '/app/roles/add')
    {
      let bcrm: any = 
      [{url: false, title: "Managment", breadcrumbs: true, type: "collapse"}
        ,{url: "/app/roles", title: "Groups", breadcrumbs: true, type: "collapse"}
        ,{url: "/app/roles/add", title: "Add Group", breadcrumbs: true, type: "item"}];
        
        this.navigationList = bcrm;
        this.titleService.setTitle(cName +' Add Group ');
    }
    else if(activeLink.startsWith('/app/roles/edit/'))
    {
      let bcrm: any = 
      [{url: false, title: "Managment", breadcrumbs: true, type: "collapse"}
        ,{url: "/app/roles", title: "Groups", breadcrumbs: true, type: "collapse"}
        ,{url: false, title: "Edit Group", breadcrumbs: true, type: "item"}];
        
        this.navigationList = bcrm;
        this.titleService.setTitle(cName +' Edit Group ');
    }
    else if(activeLink == '/app/users/add')
    {
      let bcrm: any = 
      [{url: false, title: "Managment", breadcrumbs: true, type: "collapse"}
        ,{url: "/app/users", title: "Users", breadcrumbs: true, type: "collapse"}
        ,{url: false, title: "Add User", breadcrumbs: true, type: "item"}];
        
        this.navigationList = bcrm;
        this.titleService.setTitle(cName +' Add User');
    }
    else if(activeLink.startsWith('/app/users/edit/'))
    {
      let bcrm: any = 
      [{url: false, title: "Managment", breadcrumbs: true, type: "collapse"}
        ,{url: "/app/users", title: "Users", breadcrumbs: true, type: "collapse"}
        ,{url: false, title: "Edit User", breadcrumbs: true, type: "item"}];
        
        this.navigationList = bcrm;
        this.titleService.setTitle(cName +' Edit User');
    }
    else if(activeLink == '/app/clients/add')
    {
      let bcrm: any = 
      [{url: false, title: "Setup", breadcrumbs: true, type: "collapse"}
        ,{url: "/app/clients", title: "Clients", breadcrumbs: true, type: "collapse"}
        ,{url: "/app/clients/add", title: "Add Client", breadcrumbs: true, type: "item"}];
        
        this.navigationList = bcrm;
        this.titleService.setTitle(cName +' Add Client');
    }
    else if(activeLink.startsWith('/app/clients/edit/'))
    {
      let bcrm: any = 
      [{url: false, title: "Setup", breadcrumbs: true, type: "collapse"}
        ,{url: "/app/clients", title: "Clients", breadcrumbs: true, type: "collapse"}
        ,{url: false, title: "Edit Client", breadcrumbs: true, type: "item"}];
        
        this.navigationList = bcrm;
        this.titleService.setTitle(cName +' Edit Client');
    }
    else if(activeLink.startsWith('/app/features/edit/'))
    {
      let bcrm: any = 
      [{url: false, title: "Managment", breadcrumbs: true, type: "collapse"}
        ,{url: "/app/features", title: "Features", breadcrumbs: true, type: "collapse"}
        ,{url: false, title: "Edit Feature", breadcrumbs: true, type: "item"}];
        
        this.navigationList = bcrm;
        this.titleService.setTitle(cName +' Edit Feature');
    }
    else if(activeLink.startsWith('/app/features/add'))
    {
      let bcrm: any = 
      [{url: false, title: "Managment", breadcrumbs: true, type: "collapse"}
        ,{url: "/app/features", title: "Features", breadcrumbs: true, type: "collapse"}
        ,{url: "/app/features/add", title: "Add Feature", breadcrumbs: true, type: "item"}];
        
        this.navigationList = bcrm;
        this.titleService.setTitle(cName +' Add Feature');
    }
    else if(activeLink.startsWith('/app/venues/venue-details/'))
    {
      let bcrm: any = 
      [{url: false, title: "Venue Setup", breadcrumbs: true, type: "collapse"}
        ,{url: "/app/venues", title: "Venues", breadcrumbs: true, type: "collapse"}
        ,{url: false, title: "Venue Details", breadcrumbs: true, type: "item"}];
        this.navigationList = bcrm;
        this.titleService.setTitle(' Venue Details | '+environment.defaultClinetName);
    }
    else if(activeLink.startsWith('/app/venues/edit/'))
    {
      let bcrm: any = 
      [{url: false, title: "Venue Setup", breadcrumbs: true, type: "collapse"}
        ,{url: "/app/venues", title: "Venues", breadcrumbs: true, type: "collapse"}
        ,{url: false, title: "Edit Venue", breadcrumbs: true, type: "item"}];
        this.navigationList = bcrm;
        this.titleService.setTitle(' Edit | '+environment.defaultClinetName);
    }
    else if(activeLink.startsWith('/app/venues/add'))
    {
      let bcrm: any = 
      [{url: false, title: "Venue Setup", breadcrumbs: true, type: "collapse"}
        ,{url: "/app/venues", title: "Venues", breadcrumbs: true, type: "collapse"}
        ,{url: "/app/venues/add", title: "Add Venue", breadcrumbs: true, type: "item"}];
        this.navigationList = bcrm;
        this.titleService.setTitle(' Add | '+environment.defaultClinetName);
    }
    else if(activeLink.startsWith('/app/report-designer/edit/'))
    {
      let bcrm: any = 
      [{url: false, title: "Report Designer", breadcrumbs: true, type: "collapse"}
        ,{url: "/app/report-designer", title: "Reports", breadcrumbs: true, type: "collapse"}
        ,{url: false, title: "Edit Report", breadcrumbs: true, type: "item"}];
        
        this.navigationList = bcrm;
        this.titleService.setTitle(cName +' Edit Report');
    }
    else if(activeLink.startsWith('/app/report-designer/add'))
    {
      let bcrm: any = 
      [{url: false, title: "Report Designer", breadcrumbs: true, type: "collapse"}
        ,{url: "/app/report-designer", title: "Reports", breadcrumbs: true, type: "collapse"}
        ,{url: "/app/report-designer/add", title: "Add Report", breadcrumbs: true, type: "item"}];
        
        this.navigationList = bcrm;
        this.titleService.setTitle(cName +' Add Report');
    }
    else if(activeLink.startsWith('/app/report-schedules/edit/'))
    {
      let bcrm: any = 
      [{url: false, title: "Scheduling", breadcrumbs: true, type: "collapse"}
        ,{url: "/app/report-schedules", title: "Scheduled Reports", breadcrumbs: true, type: "collapse"}
        ,{url: false, title: "Edit Schedule", breadcrumbs: true, type: "item"}];
        
        this.navigationList = bcrm;
        this.titleService.setTitle(cName +' Edit Schedule');
    }
    else if(activeLink.startsWith('/app/report-schedules/add'))
    {
      let bcrm: any = 
      [{url: false, title: "Scheduling", breadcrumbs: true, type: "collapse"}
        ,{url: "/app/report-schedules", title: "Scheduled Reports", breadcrumbs: true, type: "collapse"}
        ,{url: "/app/report-schedules/add", title: "Add Schedule", breadcrumbs: true, type: "item"}];
        
        this.navigationList = bcrm;
        this.titleService.setTitle(cName +' Add Schedule');
    }
    else if(activeLink.startsWith('/app/report-designer/preview/'))
    {
      let bcrm: any = 
      [{url: false, title: "Report Designer", breadcrumbs: true, type: "collapse"}
        ,{url: "/app/report-designer", title: "Reports", breadcrumbs: true, type: "collapse"}
        ,{url: false, title: "Preview", breadcrumbs: true, type: "item"}];
        
        this.navigationList = bcrm;
        this.titleService.setTitle(cName +' Preview ');
    }
    else if(activeLink.startsWith('/app/venueregions/'))
    {
      console.log(activeLink);
      if(activeLink.indexOf('/edit/') > 0)
      {
        console.log('edit');
        var editcrum = activeLink.substring(0, activeLink.indexOf('/edit/'));
        let bcrm: any = 
        [{url: false, title: "Venue Setup", breadcrumbs: true, type: "collapse"}
        ,{url: "/app/venues", title: "Venues", breadcrumbs: true, type: "collapse"}
        ,{url: editcrum, title: "Venue Regions", breadcrumbs: true, type: "collapse"}
        ,{url: false, title: "Edit Venue Regions", breadcrumbs: true, type: "item"}];
        
        this.navigationList = bcrm;
        this.titleService.setTitle(' Venue Regions | '+environment.defaultClinetName);
      }
      else if(activeLink.indexOf('/add') > 0)
      {
        var editcrum = activeLink.substring(0, activeLink.indexOf('/add'));
        let bcrm: any = 
        [{url: false, title: "Venue Setup", breadcrumbs: true, type: "collapse"}
        ,{url: "/app/venues", title: "Venues", breadcrumbs: true, type: "collapse"}
        ,{url: editcrum, title: "Venue Regions", breadcrumbs: true, type: "collapse"}
        ,{url: false, title: "Add Venue Regions", breadcrumbs: true, type: "item"}];
        
        this.navigationList = bcrm;
        this.titleService.setTitle(' Venue Regions | '+environment.defaultClinetName);
      }
      else
      {
        let bcrm: any = 
        [{url: false, title: "Venue Setup", breadcrumbs: true, type: "collapse"}
          ,{url: "/app/venues", title: "Venues", breadcrumbs: true, type: "collapse"}
          ,{url: false, title: "Venue Regions", breadcrumbs: true, type: "item"}];
          
          this.navigationList = bcrm;
          this.titleService.setTitle(' Venue Regions | '+environment.defaultClinetName);
      }

    }
    else if(activeLink.startsWith('/app/venueassets/'))
    {
      console.log(activeLink);
      if(activeLink.indexOf('/edit/') > 0)
      {
        console.log('edit');
        var editcrum = activeLink.substring(0, activeLink.indexOf('/edit/'));
        let bcrm: any = 
        [{url: false, title: "Venue Setup", breadcrumbs: true, type: "collapse"}
        ,{url: "/app/venues", title: "Venues", breadcrumbs: true, type: "collapse"}
        ,{url: editcrum, title: "Venue Assets", breadcrumbs: true, type: "collapse"}
        ,{url: false, title: "Edit Venue Assets", breadcrumbs: true, type: "item"}];
        
        this.navigationList = bcrm;
        this.titleService.setTitle(' Venue Assets | '+environment.defaultClinetName);
      }
      else if(activeLink.indexOf('/add') > 0)
      {
        var editcrum = activeLink.substring(0, activeLink.indexOf('/add'));
        let bcrm: any = 
        [{url: false, title: "Venue Setup", breadcrumbs: true, type: "collapse"}
        ,{url: "/app/venues", title: "Venues", breadcrumbs: true, type: "collapse"}
        ,{url: editcrum, title: "Venue Assets", breadcrumbs: true, type: "collapse"}
        ,{url: false, title: "Add Venue Assets", breadcrumbs: true, type: "item"}];
        
        this.navigationList = bcrm;
        this.titleService.setTitle(' Venue Assets | '+environment.defaultClinetName);
      }
      else
      {
        let bcrm: any = 
        [{url: false, title: "Venue Setup", breadcrumbs: true, type: "collapse"}
          ,{url: "/app/venues", title: "Venues", breadcrumbs: true, type: "collapse"}
          ,{url: false, title: "Venue Assets", breadcrumbs: true, type: "item"}];
          
          this.navigationList = bcrm;
          this.titleService.setTitle(' Venue Assets | '+environment.defaultClinetName);
      }

    }
  }
  filterNavigation(activeLink) {
    let result = [];
    let title = 'Welcome';
    this.navigation.forEach((a) => {
      if (a.type === 'item' && 'url' in a && a.url === activeLink) {
        result = [
          {
            url: ('url' in a) ? a.url : false,
            title: a.title,
            breadcrumbs: ('breadcrumbs' in a) ? a.breadcrumbs : true,
            type: a.type
          }
        ];
        title = a.title;
      } else {
        if (a.type === 'group' && 'children' in a) {
          a.children.forEach((b) => {
            if (b.type === 'item' && 'url' in b && b.url === activeLink) {
              result = [
                {
                  url: ('url' in b) ? b.url : false,
                  title: b.title,
                  breadcrumbs: ('breadcrumbs' in b) ? b.breadcrumbs : true,
                  type: b.type
                }
              ];
              title = b.title;
            } else {
              if (b.type === 'collapse' && 'children' in b) {
                b.children.forEach((c) => {
                  if (c.type === 'item' && 'url' in c && c.url === activeLink) {
                    result = [
                      {
                        url: ('url' in b) ? b.url : false,
                        title: b.title,
                        breadcrumbs: ('breadcrumbs' in b) ? b.breadcrumbs : true,
                        type: b.type
                      },
                      {
                        url: ('url' in c) ? c.url : false,
                        title: c.title,
                        breadcrumbs: ('breadcrumbs' in c) ? c.breadcrumbs : true,
                        type: c.type
                      }
                    ];
                    title = c.title;
                  } else {
                    if (c.type === 'collapse' && 'children' in c) {
                      c.children.forEach((d) => {
                        if (d.type === 'item' && 'url' in d && d.url === activeLink) {
                          result = [
                            {
                              url: ('url' in b) ? b.url : false,
                              title: b.title,
                              breadcrumbs: ('breadcrumbs' in b) ? b.breadcrumbs : true,
                              type: b.type
                            },
                            {
                              url: ('url' in c) ? c.url : false,
                              title: c.title,
                              breadcrumbs: ('breadcrumbs' in c) ? c.breadcrumbs : true,
                              type: c.type
                            },
                            {
                              url: ('url' in d) ? d.url : false,
                              title: d.title,
                              breadcrumbs: ('breadcrumbs' in c) ? d.breadcrumbs : true,
                              type: d.type
                            }
                          ];
                          title = d.title;
                        }
                      });
                    }
                  }
                });
              }
            }
          });
        }
      }
    });
    this.navigationList = result;
    var cClient = this.authService.getCurrentClient();
    var cName = '';
    var heading = '';
    if(title)
    {
      heading = title;
    }
    if(cClient && cClient.name)
    {
      cName = cClient.name + ' | ';
    }
    this.titleService.setTitle(cName + heading + ' ');
  }

}
