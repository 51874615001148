
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { EAUTHActions, Get, GetSuccess} from '../actions/auth.action';

@Injectable()
export class AuthEffects {

    constructor(private _actions$: Actions) { }

    @Effect()
    Get: Observable<any> = this._actions$.pipe(
        ofType<Get>(EAUTHActions.GET),
        switchMap(() => {
            return null;
            /*
            this.dashboardService.get()
                .pipe(
                    map((result) => {
                        if (result && result['code'] === 200 && result['data']) {
                            return new GetSuccess(result['data'], null);
                        } else {
                            this.notify.error(result['message'], "Failed Get");
                            return new GetSuccess(null, result['message']);
                        }
                    })
                    , catchError((error) => {
                        return of(new GetSuccess(null, error));
                    }));*/
        }));
}
