export const errors = {
  list: [
    {
      key: "",
      value: "Error occur at api, please try again later."
    },
    {
      key: "",
      value: ""
    }
]
};

