<div *ngIf="!loadingMain">
    <app-navigation *ngIf="showTopbar" class="pcoded-navbar" [ngClass]="{'navbar-collapsed' : navCollapsed, 'theme-horizontal': this.flatConfig['layout'] === 'horizontal', 'mob-open' : navCollapsedMob}" (onNavMobCollapse)="navMobClick()"></app-navigation>
    <app-nav-bar *ngIf="showSidebar" class="navbar pcoded-header navbar-expand-lg navbar-light" (onNavCollapse)="this.navCollapsed = !this.navCollapsed;" (onNavHeaderMobCollapse)="navMobClick()"></app-nav-bar>
    <div class="pcoded-main-container">
        <div class="pcoded-content" [ngClass]="{'container': this.flatConfig.layout === 'horizontal' && this.flatConfig.subLayout === 'horizontal-2'}">
            <app-breadcrumb *ngIf="showBreadcrums"></app-breadcrumb>
            <router-outlet></router-outlet>
        </div>
    </div>
    <app-configuration></app-configuration>
</div>
<div id="preloader" *ngIf="loadingMain">
    <div id="loader"></div>
</div>